.gift-container-compatibility .card-imei-container,
.gift-container-compatibility .card-zip-container {
    display: flex;
    flex-direction: column;
    background: white;
    text-align: left;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    border-radius: 28px;
    height: 163px;
    padding: 30px 35px;
}

.gift-container-compatibility {
    height: 100vh !important;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
}
.gift-container-compatibility .message-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
}
.gift-container-compatibility .card-zip-container .card-label {
    font-size: 13px;
    font-family: "Poppins-regular";
}
.gift-container-compatibility .card-imei-container {
    height: auto;
}
.gift-container-compatibility .font-semibold {
    font-family: "Poppins-SemiBold";
}
.gift-container-compatibility .font-bold {
    font-family: "Poppins-bold";
}
.gift-container-compatibility .purple-color {
    color: #a361ff;
}

@media screen and (max-width: 899px) {
    .gift-container-compatibility .card-imei-container,
    .gift-container-compatibility .card-zip-container {
        color: white;
        background: transparent;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        width: 100%;
    }
    .gift-container-compatibility {
        padding-top: 30px;
    }
}

