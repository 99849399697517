/*** Variables ***/
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

$icon-color-purple: #6c3cec;
$icon-color-blue: #04f3fb;
$icon-color-pink: #fc2bdc;

$text-color-purple: rgb(123, 74, 187);
$text-color-purple-gray: #6a4d9a;
$text-color-purple-gray-strong: rgb(50, 12, 94);

$text-color-pink-links: #ff00dd;

$color-button-pink: #f761ff;
$color-button-purple: #a17fff;
