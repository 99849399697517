@font-face {
    font-family: "Poppins-Regular";
    src: url("../../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../../fonts/poppins/Poppins-Regular.otf") format("opentype");
}

$poppins-regular: "Poppins-Regular, Arial, Helvetica, sans-serif";
