.container-payment-error .error-image {
    width: 170px;
    height: 170px;
}
@media (min-width: 900px) {
    .container-payment-error .error-image {
        width: 180px;
        height: 180px;
    }
}

