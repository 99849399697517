.gift-welcome-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    gap: 200px;
    padding: 16px;
    .gift-image {
        display: none;
    }
    .font-bold {
        font-family: "Poppins-bold";
    }
    .font-medium {
        font-family: "Poppins-Medium";
    }
    .font-semibold {
        font-family: "Poppins-SemiBold";
    }

    .purple-color {
        color: #a361ff;
    }
    .white-color {
        color: #fff;
    }

    .img-container {
        width: auto;
    }
}

@media screen and (max-width: 899px) {
    .gift-welcome-container {
        flex-direction: column !important;
        padding: 0px !important;
        margin: 0px !important;
        height: 100vh;
        .gift-image {
            display: block;
        }
        font-family: "Poppins-Regular";

        .logo-dalefon {
            display: none;
        }
    }

    .check-email-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: transparent
            linear-gradient(184deg, #200340 0%, #2d0246 100%) 0% 0% no-repeat
            padding-box;

        text-align: center;
    }

    .img-container {
        display: none;
    }

    .movil-container {
        height: 100vh;
        background: transparent
            linear-gradient(184deg, #200340 0%, #2d0246 100%) 0% 0% no-repeat;
    }
}
