/*** Variables ***/
.content-card-container .card-item h1 {
    font-family: "Poppins-SemiBold";
}
.content-card-container .card-item .bag-item-container {
    transition: 0.8s;
}
.content-card-container .card-item .bag-item-container .service-item-wrapper {
    font-family: "Poppins-Regular";
    padding-bottom: 0;
}
.content-card-container
    .card-item
    .bag-item-container
    .service-item-wrapper
    .service-item-title {
    font-family: "Poppins-SemiBold";
}
.content-card-container
    .card-item
    .bag-item-container
    .service-item-wrapper
    .service-item-title
    .expand-icon {
    color: #ff00dd;
}

