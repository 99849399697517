/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;
/*** Tipografía ***/
@use "../typography.scss" as t;

.gift-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gift-not-found-container {
    .gift-not-found-legend {
        text-align: center;
    }
}

.gift-not-found-container,
.gift-wrapper {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
    .gift-header {
        padding: 0;
        display: flex;
        align-items: center;
        .gift-header-image {
            width: 100%;
            height: 90%;
        }
        .logo {
            height: 60px;
        }
        p {
            margin-top: 20px;
            font-family: t.$poppins-bold;
            font-size: 65px;
            line-height: 20px;
            margin: 0;
        }
        h1 {
            font-family: t.$poppins-extrabold;
            color: white;
            font-size: 75px;
            margin: 20px 0;
            font-style: italic;
        }
    }
}

@media screen and (max-width: 899px) {
    .gift-wrapper {
        .gift-header {
            margin-bottom: 0px;
            .gift-header-image {
                display: none !important;
            }
            .logo {
                padding-top: 30px;
            }
        }
    }
}

.gift-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.gift-content {
    max-width: 1200px !important;
    width: 100%;
    margin: 50px 0 !important;
    border-radius: 30px;
    background-image: url(../images/landings/gift/gift-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    position: relative;
    .gift-img {
        max-height: 230px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .gift-text {
        padding: 30px 50px;
        .logo {
            height: 50px;
            margin-top: 30px;
        }
        h1 {
            font-size: 45px;
            line-height: 53px;
            font-family: t.$poppins-bold;
        }
        p {
            font-size: 17px;
            span {
                font-family: t.$poppins-extrabold;
                font-size: 20px;
            }
        }
    }
    .gift-form {
        width: 100%;
        color: #505050;
        background-color: white;
        border-radius: 20px;
        padding: 0 20px 10px 0;
        display: flex;
        flex-direction: column;
        display: flex;
        justify-content: center;
        h1 {
            color: #404040;
            margin-top: 0;
        }
        input {
            box-sizing: border-box;
        }
        .gift-btn {
            font-family: "Poppins-Bold";
            background: rgb(0, 0, 0);
            background: linear-gradient(
                174deg,
                rgb(102 63 130) 0%,
                rgb(77, 18, 156) 100%
            );
            width: 100%;
            padding: 10px;
            border-radius: 50px;
            margin-bottom: 30px;
        }
        .gift-btn:hover {
            background: rgb(0, 0, 0);
            background: linear-gradient(
                174deg,
                rgb(84 62 84) 0%,
                rgb(42, 3, 93) 100%
            );
        }
    }
    .gift-response {
        padding: 30px auto;
        .download {
            border-radius: 15px;
            border: 1px solid #dedede;
            padding: 20px;
            width: 60%;
            margin: 0 auto;
            margin-top: 20px;
            h3 {
                font-size: 16px;
                font-weight: 900 !important;
            }
        }
        &.error {
            margin-top: 25px;
            text-align: center;
            line-height: 20px;
            h3,
            h4 {
                color: rgba(228, 46, 46, 0.781);
            }
            a {
                padding-top: 20px;
                font-weight: 900;
                text-decoration: none;
                color: rgba(228, 46, 46, 0.781);
            }
            svg {
                font-size: 35px;
                color: rgba(228, 46, 46, 0.781);
            }
        }
        &.success {
            margin-top: 25px;
            text-align: center;

            h3 {
                color: #269626;
                font-size: 18px;
                font-weight: 500;
                margin-top: 0;
                span {
                    font-weight: 900;
                }
            }
            svg {
                font-size: 45px;
                color: rgb(62, 40, 137);
                margin-bottom: 20px;
            }
            .success-svg {
                height: 50px !important;
            }
        }
    }
    .footer {
        .contact {
            .data {
                img {
                    width: 18px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .gift-content {
        margin: 25px 0 !important;
        padding: 0 20px 40px;
        background-image: none !important;
        .gift-logo {
            height: 60px;
            margin-bottom: 30px;
            margin: 0 auto !important;
        }
        .gift-img {
            display: none !important;
        }
        .gift-form {
            margin: 0;
            padding: 15px;
        }
        .gift-text {
            text-align: center !important;
            margin-bottom: 20px;
            padding: 0;
        }
    }
}

.MuiLoadingButton-loadingIndicator {
    color: white !important;
}

.esim-gift-confirmation {
    width: auto !important;
    min-width: 0 !important;
    max-width: none !important;
    max-height: 400px;
}
