/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;
/*** Tipografía ***/
@use "../typography.scss" as t;

.modal-update-client-data {
    max-width: 700px !important;
    width: 100%;
    background: rgb(93, 7, 154);
    background: radial-gradient(
        circle,
        rgba(93, 7, 154, 1) 0%,
        rgba(57, 17, 85, 1) 100%,
        rgba(57, 17, 85, 1) 100%
    );
    color: white;
    padding: 35px 50px;
    border-radius: 30px;
    margin: 0 auto !important;
    .left-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        h1 {
            font-size: 48px;
            font-family: t.$poppins-bold;
            line-height: 58px;
            margin: 0;
        }
        img {
            width: 305px;
            margin-top: 50px;
        }
    }
    .modal-title {
        display: none;
    }
    .modal-image {
        display: none;
    }
    .modal-subtitle {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .modal-textfield {
        background-color: rgb(247, 245, 245);
        color: #000;
        margin-top: 15px;
        border-radius: 5px;
    }
    .secondary-button {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background: none;
        text-transform: none;
        box-shadow: none;
        font-family: t.$poppins-medium;
        font-size: 16px;
    }
    .secondary-button:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
        color: #f1aae5;
        background-color: transparent !important;
        box-shadow: none;
    }
    .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
        color: rgba(255, 255, 255, 0.9) !important;
    }
}
.success-dialog {
    text-align: center;
    padding: 16px;
}

@include m.mobile() {
    .modal-update-client-data {
        padding: 35px;
        .modal-image {
            display: block;
            img {
                width: 200px;
                margin-top: 30px;
            }
        }
        .modal-title {
            display: block;
            font-size: 40px;
            margin: 0 0 20px;
            h1 {
                font-size: 56px;
                font-family: t.$poppins-bold;
                line-height: 58px;
                margin: 0;
            }
        }
        .left-column {
            h1 {
                display: none;
            }
            img {
                display: none;
            }
        }
    }
}
