@use "card-prepaid_config" as config;

//Styles Prepaids:
.container-card-prepaid {
    .card-prepaids {
        background: rgb(144, 205, 252);
        background: linear-gradient(150deg, #393c8e 30%, #a42e7b 100%);
        box-shadow:
            rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

        color: white;
        border-radius: 20px;
        position: relative;
        z-index: 2;
    }

    .card-prepaids-best-selling {
        border: solid 4px rgb(255, 0, 242);
        animation: myanim 3s infinite;
        padding-top: 2rem;
        padding-bottom: 0.5rem;
    }

    .card-prepaids-best-selling .title-best-selling {
        background-color: rgb(255, 0, 242);
        animation: myanim 3s infinite;
        border-radius: 15px;
    }

    .card-prepaids a {
        text-decoration: none;
    }

    .card-prepaids a .btn-card {
        color: white;
        background-color: config.$color-button-pink;
        padding: 10px;
        border-radius: 20px;
        transition: all 0.5s ease;
        font-weight: bold;
    }

    .card-prepaids a .btn-card:hover {
        background-color: config.$color-button-purple;
    }

    .circular-icon {
        background-color: #a42e7b;
        border-radius: 50%;
        padding: 3px;
    }

    .img-social-media-card-prepaid {
        width: 300px;
        height: 170px;
    }

    .img-shadow-card-prepaid {
        position: absolute;
        mix-blend-mode: multiply !important;
        bottom: -38px;
        z-index: 1;
        width: 300px;
        @media (min-width: config.$breakpoint-md) {
            width: 350px;
            bottom: -40px;
        }
    }
}
