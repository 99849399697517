.container-wbc-purchase-page {
    font-family: Poppins-Regular;
}
.container-wbc-purchase-page .container-invoice-your-recharge .img-invoice {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0px;
    right: 20px;
}
@media (min-width: 900px) {
    .container-wbc-purchase-page .container-invoice-your-recharge .img-invoice {
        width: 127px;
        height: 127px;
        top: -15px;
    }
}

