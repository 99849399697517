%cardStyles {
    display: flex;
    flex-direction: column;
    background: white;
    text-align: left;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    height: 163px;
    padding: 30px 35px;
}

.gift-container-compatibility {
    height: 100vh !important;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    .message-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        color: white;
    }

    .card-zip-container {
        @extend %cardStyles;
        .card-label {
            font-size: 13px;
            font-family: "Poppins-regular";
        }
    }

    .card-imei-container {
        @extend %cardStyles;
        height: auto;
    }

    .font-semibold {
        font-family: "Poppins-SemiBold";
    }

    .font-bold {
        font-family: "Poppins-bold";
    }

    .purple-color {
        color: #a361ff;
    }
}

@media screen and (max-width: 899px) {
    %cardStylesResponsibe {
        color: white;
        background: transparent;
        box-shadow: none;
        padding: 0px;
        margin: 0px;
        width: 100%;
    }
    .gift-container-compatibility {
        padding-top: 30px;
        .card-zip-container {
            @extend %cardStylesResponsibe;
        }
        .card-imei-container {
            @extend %cardStylesResponsibe;
        }
    }
}
