.gift-coverage-container {
    width: 100%;
    .font-bold {
        font-family: "Poppins-Bold";
    }
    .font-semibold {
        font-family: "Poppins-SemiBold";
    }
    .font-medium {
        font-weight: 500;
        font-family: "Poppins-Medium";
    }
    .purple-color {
        color: #a361ff;
    }
    .white-color {
        color: #fff;
    }
    .link-coverage-continue {
        color: #8971ad;
        text-decoration: none;
        cursor: pointer;
        margin-top: 25px;
        text-align: center;
        font-size: 14px;
        background-color: rgb(233 227 245);
        width: 50%;
        padding: 10px;
        border-radius: 50px;
        font-weight: 600;
        margin: 0 auto;
        @media screen and (max-width: 899px) {
            color: #fff;
            background-color: rgba(36, 5, 100, 0.26);
        }
    }

    .link-coverage-map {
        text-decoration: none;
        cursor: pointer;
        @media screen and (max-width: 899px) {
            text-align: center;
        }
    }

    .chart-coverage {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        color: #fff;
        width: 350px;
        .coverage-good {
            position: absolute;
            bottom: 15px;
            left: 245px;
        }
        .coverage-bad {
            position: absolute;
            bottom: 15px;
            right: 250px;
        }
        .coverage-regular {
            position: absolute;
        }
    }
}
