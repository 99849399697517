/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;
/*** Tipografía ***/
@use "../typography.scss" as t;

.invoice-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.invoice-not-found-container {
    .invoice-not-found-legend {
        text-align: center;
    }
}

.invoice-not-found-container,
.invoice-wrapper {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
    .qr-header {
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        .qr-header-image {
            width: 100%;
            height: 90%;
        }
        .logo {
            height: 60px;
            margin-bottom: 90px;
        }
        p {
            margin-top: 20px;
            font-family: t.$poppins-bold;
            font-size: 65px;
            line-height: 20px;
            margin: 0;
        }
        h1 {
            font-family: t.$poppins-extrabold;
            color: white;
            font-size: 75px;
            margin: 20px 0;
            font-style: italic;
        }
    }
    .qr-introduction {
        border-radius: 20px 20px 0 0;
        background: rgb(29, 3, 68);
        background: linear-gradient(
            360deg,
            rgba(29, 3, 68, 1) 0%,
            rgba(67, 43, 116, 1) 100%
        );
        padding: 65px 50px 65px 15px;
        h1 {
            font-size: 40px;
            font-family: t.$poppins-bold;
        }
        p {
            font-size: 20px;
        }
        .label {
            font-size: 24px;
            font-family: t.$poppins-extrabold;
        }
    }
    .bg-container {
        background-image: url("assets/images/landings/esim-activation/qr-bg-lines-1.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        .qr-code-container {
            margin: 85px 0 210px;
            display: flex;
            justify-content: center;
            .qr-code {
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    font-size: 50px;
                    font-family: "Poppins-ExtraBold";
                    margin-bottom: 0;
                    margin-top: 20px;
                    text-align: center;
                }
                p {
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;
                }
                .qr-box {
                    margin-top: 30px;
                    padding: 30px 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(0, 0, 0, 0.2);
                    font-size: 20px;
                    border-radius: 20px;
                    max-width: 800px;
                    width: 100%;
                    text-align: center;
                    h3 {
                        margin: 0;
                        font-family: "Poppins-SemiBold";
                    }
                    .qr-image {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                h5 {
                    font-family: t.$poppins-regular;
                    font-size: 15px;
                    font-weight: 500;
                    span {
                        font-family: t.$poppins-extrabold;
                        font-style: italic;
                    }
                }
                .inactive {
                    h1 {
                        font-size: 35px;
                    }
                    p {
                        font-size: 17px;
                    }
                    h3 {
                        font-family: t.$poppins-bold;
                        line-height: 40px;
                    }
                    h4 {
                        font-family: t.$poppins-semibold;
                    }
                    h2 {
                        font-size: 40px;
                        font-family: t.$poppins-extrabold;
                        letter-spacing: 2px;
                    }
                }
            }
        }
        .qr-second-video {
            display: flex;
            align-items: center;
            h1 {
                font-size: 40px;
                font-family: t.$poppins-bold;
                line-height: 60px;
            }
            p {
                font-size: 18px;
                line-height: 30px;
            }
            .label {
                font-size: 24px;
                font-family: t.$poppins-extrabold;
            }
        }
    }
    .qr-introduction,
    .qr-second-video {
        .video-responsive {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            padding-top: 30px;
            position: relative;
        }
        .video-responsive iframe,
        .video-responsive object,
        .video-responsive embed {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    .qr-footer {
        background: #15002a;
        padding: 10px 0;
        text-align: center;
        margin-top: 150px;
    }
}

@media screen and (max-width: 899px) {
    .invoice-wrapper {
        .qr-header {
            margin-bottom: 15px;
            .qr-header-image {
                display: none !important;
            }
        }
        .qr-introduction {
            padding: 20px 0;
            background: none;
        }
        .bg-container {
            background-image: none;
            .qr-code-container {
                margin: 50px 0 0;
                .qr-code {
                    h1 {
                        margin-top: 0;
                        font-size: 40px !important;
                    }
                    .qr-box {
                        padding: 20px;
                        h3 {
                            margin: 20px 0 25px !important;
                            font-family: "Poppins-Bold";
                        }
                        .qr-image {
                            display: flex;
                            justify-content: center !important;
                        }
                    }
                    .inactive h2 {
                        font-size: 40px;
                    }
                }
            }
        }
        .qr-footer {
            margin-top: 50px;
        }
        .video-responsive {
            margin: 30px auto !important;
            display: flex;
            justify-content: center;
        }
    }
}

.invoice-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.active-qr-image {
    width: 100%;
    height: auto;
}

.invoice-content {
    .invoice-logo {
        height: 50px;
        margin-bottom: 30px;
    }
    .invoice-img {
        max-width: 500px;
        width: 100%;
        height: auto;
    }
    .invoice-form {
        width: 100%;
        color: #ffffff;
        background: rgb(187, 136, 255);
        background: linear-gradient(
            180deg,
            rgba(187, 136, 255, 0.17979691876750703) 0%,
            rgba(62, 32, 85, 0) 100%
        );
        border-radius: 20px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        display: flex;
        justify-content: center;
        h1 {
            color: #ffffff;
            margin-top: 0;
        }
        h4 {
            margin: 0;
            font-size: 18px;
            font-family: t.$poppins-regular !important;
            font-weight: 500;
        }
        svg {
            color: white;
            &.help {
                font-size: 15px;
            }
        }
        svg:hover {
            color: #e078d1;
        }
        .InputLabel {
            color: #ffffff;
            margin-top: 0;
        }
        input {
            color: #ffffff;
            box-sizing: border-box;
        }
        .invoice-btn {
            font-family: "Poppins-Bold";
            background: rgb(0, 0, 0);
            background: linear-gradient(
                174deg,
                rgb(102 63 130) 0%,
                rgb(77, 18, 156) 100%
            );
            width: 100%;
            padding: 10px;
            border-radius: 50px;
            margin-bottom: 30px;
        }
        .invoice-btn:hover {
            background: rgb(0, 0, 0);
            background: linear-gradient(
                174deg,
                rgb(89, 54, 114) 0%,
                rgb(77, 18, 156) 100%
            );
        }
    }

    .invoice-response {
        padding: 30px auto;
        .download {
            border-radius: 15px;
            border: 1px solid #dedede;
            padding: 20px;
            width: 60%;
            margin: 0 auto;
            margin-top: 20px;
            h3 {
                font-size: 16px;
                font-weight: 900 !important;
            }
        }
        &.error {
            text-align: center;
            a {
                padding-top: 20px;
                font-weight: 900;
                text-decoration: none;
            }
            svg {
                font-size: 35px;
                color: red;
            }
        }
        &.success {
            text-align: center;
            h3 {
                font-size: 18px;
                font-weight: 500;
                margin-top: 0;
                span {
                    font-weight: 900;
                }
            }
            svg {
                font-size: 45px;
                color: rgb(62, 40, 137);
                margin-bottom: 20px;
            }
            .success-svg {
                height: 50px !important;
            }
        }
    }
    .footer {
        .contact {
            .data {
                img {
                    width: 18px !important;
                }
            }
        }
    }
}

.invoice-data {
    padding: 15px 30px;
    background-color: rgb(114, 75, 155, 10%);
    border-radius: 20px;
    color: white;
    margin-bottom: 30px !important;
    h1 {
        font-size: 22px;
        margin: 0;
    }
    h2 {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
    }
    .invoice-data-right {
        text-align: right;
    }
    .invoice-data-left {
        text-align: left;
    }
}

.invoice-download-invoice {
    text-align: center;
    background-color: #210946;
    color: white;
    width: 100%;
    h1 {
        font-size: 37px;
        margin-top: 5px;
    }
    p {
        font-size: 21px;
        font-family: t.$poppins-regular;
    }
    .success-image {
        width: auto;
        height: 90px;
    }
    .download-form {
        max-width: 500px;
        width: 100%;
        color: #ffffff;
        background: rgb(187, 136, 255);
        background: linear-gradient(
            180deg,
            rgba(187, 136, 255, 0.17979691876750703) 0%,
            rgba(62, 32, 85, 0) 100%
        );
        border-radius: 20px;
        padding: 35px;
        display: flex;
        margin-top: 30px;
        h1 {
            color: #ffffff;
            margin-top: 0;
        }
        h3 {
            font-weight: 500;
            font-size: 16px;
        }
        .pdf-image,
        .xml-image {
            width: auto;
            height: 70px;
            margin-top: 15px;
        }
    }
}

.footer {
    .contact {
        .data {
            img {
                width: 18px !important;
            }
        }
    }
}

@media screen and (max-width: 899px) {
    .invoice-content {
        .invoice-logo {
            height: 45px;
            margin-bottom: 30px;
        }
        .invoice-img {
            display: none !important;
        }
        h1 {
            margin-top: 0 !important;
        }
        .invoice-form {
            padding: 10px 20px;
            &.step-1 {
                input {
                    height: auto !important;
                }
            }
            h4 {
                margin: 15px 0 0;
                font-size: 19px;
                font-weight: normal;
            }
        }
    }
    .invoice-download-invoice {
        h1 {
            font-size: 30px;
            font-family: t.$poppins-bold;
            margin-top: 5px;
        }
        p {
            font-size: 18px;
            line-height: 26px;
            margin: 0;
        }
        .success-image {
            width: auto;
            height: 80px;
        }
        .download-form {
            margin: 30px 15px;
            flex-direction: column;
            h1 {
                color: #ffffff;
                margin-top: 0;
            }
            .pdf-image,
            .xml-image {
                width: auto;
                height: 80px;
            }
            .pdf-image {
                margin: 15px auto 40px;
            }
        }
    }
    .invoice-data {
        padding: 20px !important;
        width: 100%;
        .invoice-data-right,
        .invoice-data-left {
            text-align: left !important;
        }
    }
}

.invoice-form span {
    color: #ffffff;
    font-family: t.$poppins-regular;
    font-size: 17px;
}
.test {
    color: #ffffff !important;
    // background-color: #ffffff;
}
.invoice-texfield {
    background-color: #ffffff;
    border-radius: 5px;
}

/* Estilos para los Radio */
.radio-input {
    span svg:first-child path {
        color: #ffffff;
    }
    svg:last-child path {
        color: #ff28df;
        border-radius: #ff28df;
    }
}

.invoice-content {
    .invoice-form {
        &.step-1 {
            input {
                height: auto !important;
            }
        }
    }
}

.invoice-content {
    .invoice-flow {
        input {
            color: #030000;
        }
    }
}
