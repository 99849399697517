@charset "UTF-8";
/*** Tipografía ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Variables ***/
/*** Responsivo ***/
html,
body {
    margin: 0;
    padding: 0;
    background-color: #ffffff !important;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.full-width {
    width: 100% !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.app-main {
    display: block;
    width: 100%;
    margin: 0;
}

form,
input,
label {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
}

.terms-conditions span {
    font-weight: 500 !important;
    font-size: 15px;
    color: #050505 !important;
}
.terms-conditions a {
    text-decoration: none;
    font-weight: 600;
    color: #fd45d9 !important;
}
.terms-conditions a:hover {
    color: #d827b5 !important;
}

/* REMOVE INPUT ARROWS WHEN TYPE=NUMBER / UX */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.success-form h1 {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif !important;
    font-size: 25px !important;
    margin-bottom: 0;
    color: white !important;
}
.success-form p {
    font-size: 18px;
}

.purchase-form span,
.activation-form span {
    color: #8e8e8e;
    font-size: 14px;
    margin: 0;
}
.purchase-form .email-box,
.activation-form .email-box {
    background: #ffffff;
    border-radius: 15px;
    padding: 15px 25px 10px;
    margin-top: 25px;
    border: 1px solid #cfcfcf;
}
.purchase-form .email-box p,
.activation-form .email-box p {
    margin: 0px 0;
    line-height: 25px;
}
.purchase-form .email-confirmation-box,
.activation-form .email-confirmation-box {
    background-color: #fff;
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #cdcdcd;
}
.purchase-form .email-confirmation-box p,
.activation-form .email-confirmation-box p {
    margin-bottom: 20px;
    margin-top: 0;
}
.purchase-form.no-compatible h2,
.activation-form.no-compatible h2 {
    font-size: 35px !important;
    margin-bottom: 0;
}
.purchase-form.no-compatible h3,
.activation-form.no-compatible h3 {
    font-size: 25px !important;
}
.purchase-form.no-compatible p,
.activation-form.no-compatible p {
    font-size: 20px;
}
.purchase-form form,
.activation-form form {
    position: relative;
    padding: 10px 35px !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
}
.purchase-form form .success-p,
.activation-form form .success-p {
    width: 100%;
    margin: 0 auto;
    font-size: 17px;
}
.purchase-form form .success-p h4,
.activation-form form .success-p h4 {
    font-size: 22px !important;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
.purchase-form form .activation-text span,
.activation-form form .activation-text span {
    font-weight: bold;
    color: #fd45d9;
}
.purchase-form form .activation-text img,
.activation-form form .activation-text img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}
.purchase-form form .purchase-text h1,
.activation-form form .purchase-text h1 {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
.purchase-form form .purchase-text p,
.activation-form form .purchase-text p {
    margin-bottom: 0px;
}
.purchase-form form .purchase-text span,
.activation-form form .purchase-text span {
    font-weight: bold;
    color: #000;
}
.purchase-form form .activation-title-container,
.purchase-form form .purchase-title-container,
.activation-form form .activation-title-container,
.activation-form form .purchase-title-container {
    display: flex;
    align-items: center;
    height: 0;
}
.purchase-form form .activation-textfield-container,
.purchase-form form .purchase-textfield-container,
.activation-form form .activation-textfield-container,
.activation-form form .purchase-textfield-container {
    margin: 10px 0;
}
.purchase-form form .centered,
.activation-form form .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.purchase-form form .centered h1,
.activation-form form .centered h1 {
    color: #181818;
    font-size: 30px;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    margin: 0;
}
.purchase-form form .centered h4,
.activation-form form .centered h4 {
    font-size: 27px;
}
.purchase-form form .centered img,
.activation-form form .centered img {
    margin: 20px 0;
}
.purchase-form form .centered .span,
.activation-form form .centered .span {
    font-weight: bold;
    color: #fd45d9;
}
.purchase-form form .confirmation-box,
.activation-form form .confirmation-box {
    margin: 20px 0;
    padding: 10px 0;
    border: 1px solid #dddddd;
    border-radius: 10px;
    background-color: white;
}
.purchase-form form .confirmation-box .total,
.activation-form form .confirmation-box .total {
    margin: 20px 0 0;
    background-color: #f7f3ff;
    font-size: 22px;
    padding: 10px 30px !important;
    font-weight: bold;
    color: #2f2236;
}
.purchase-form form .confirmation-box .line,
.activation-form form .confirmation-box .line {
    display: flex;
    padding: 0 30px;
    align-items: center;
    text-align: left;
}
.purchase-form form .confirmation-box .line span,
.activation-form form .confirmation-box .line span {
    font-weight: bold;
    color: #383838;
}
.purchase-form form .confirmation-box .line p,
.activation-form form .confirmation-box .line p {
    margin: 10px 0;
    text-align: right !important;
}
.purchase-form form .confirmation-box .line .monthly,
.activation-form form .confirmation-box .line .monthly {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
}
.purchase-form form .list,
.activation-form form .list {
    display: flex;
    flex-wrap: nowrap;
    margin: 15px 0;
}
.purchase-form.error,
.activation-form.error {
    padding: 50px 50px 0;
}
.purchase-form.error .activation-error-button-container,
.purchase-form.error .purchase-error-button-container,
.activation-form.error .activation-error-button-container,
.activation-form.error .purchase-error-button-container {
    justify-content: center;
    padding: 10px 0;
    margin: 0;
}
.purchase-form.error
    .activation-error-button-container
    .activation-error-button,
.purchase-form.error .activation-error-button-container .purchase-error-button,
.purchase-form.error .purchase-error-button-container .activation-error-button,
.purchase-form.error .purchase-error-button-container .purchase-error-button,
.activation-form.error
    .activation-error-button-container
    .activation-error-button,
.activation-form.error
    .activation-error-button-container
    .purchase-error-button,
.activation-form.error
    .purchase-error-button-container
    .activation-error-button,
.activation-form.error .purchase-error-button-container .purchase-error-button {
    font-size: 17px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    text-transform: none;
    color: #fd45d9 !important;
}

/* NAVIGATION */
.navigation {
    width: 100%;
    height: auto;
    color: #ffffff;
    padding: 20px 30px;
}
.navigation .logo-container {
    display: grid;
    align-content: center;
}
.navigation .logo-container .logo {
    width: 160px;
    height: auto;
    vertical-align: middle;
}
.navigation .menu {
    text-align: end;
}
.navigation .menu li {
    list-style: none;
    display: inline;
    padding: 0 40px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
}
.navigation .menu li a {
    text-decoration: none !important;
    color: white !important;
}

@media screen and (max-width: 767px) {
    .navigation {
        padding: 10px;
    }
    .navigation .logo-container {
        display: none;
    }
}
/* SUBNAVIGATION */
.MuiMenu-list {
    font-size: 17px;
    color: white;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    background: #492677;
    box-shadow:
        rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}
.MuiMenu-list svg {
    fill: white;
}
.MuiMenu-list .MuiMenuItem-root {
    padding: 10px 20px;
}
.MuiMenu-list .MuiMenuItem-root:hover {
    background: #441186;
}

/** HEADER **/
.header {
    color: #ffffff;
    margin: 0 0 10px;
}
.header .home h1 {
    font-size: 50px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.header .home p {
    font-size: 28px;
}
.header .home p span {
    color: #34007a;
    font-weight: bold;
    background: #ffffff;
    padding: 2px 16px 4px;
    font-size: 27px;
    border-radius: 30px;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
.header .home .image-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 767px) {
    .header .home {
        padding: 0 30px;
    }
}
.header .products {
    font-size: 30px;
    text-align: left;
    letter-spacing: 1px;
}
.header .products .title {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
    text-align: left;
    margin-top: 0;
    font-size: 52px;
    line-height: 40px;
    font-weight: 500;
}
.header .products .subtitle {
    font-size: 55px;
    color: #a361ff;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.header .products .hypertitle {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif !important;
    text-align: left;
    margin-top: 0;
    font-size: 56px;
    line-height: 40px;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .header .home {
        margin: 0 auto !important;
        text-align: center !important;
    }
    .header .home p {
        padding-bottom: 20px;
    }
    .header .button-container {
        display: flex;
        justify-content: center !important;
    }
    .header .header-image {
        display: none;
    }
}
@media screen and (min-width: 899px) {
    .products-tab-container {
        margin-top: 35px !important;
    }
}
@media screen and (max-width: 600px) {
    .header .products {
        margin-left: 0 !important;
        padding: 15px;
    }
    .header .products .title {
        font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
        text-align: left;
        margin-top: 0;
        font-size: 48px;
        line-height: 40px;
        font-weight: 500;
    }
    .header .products .subtitle {
        font-size: 52px;
        color: #a361ff;
        font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    }
    .header .products .hypertitle {
        font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif !important;
        text-align: left;
        margin-top: 0;
        font-size: 52px;
        line-height: 40px;
        font-weight: 500;
    }
}
@media screen and (max-width: 1320px) {
    .products-image {
        position: absolute;
        right: 70px !important;
    }
    .products-image .products-header-image {
        height: 365px !important;
    }
}
@media screen and (max-width: 1000px) {
    .products-image .products-header-image {
        display: none !important;
    }
}
/** FOOTER **/
.footer-container {
    max-width: 100% !important;
    margin: 0 auto !important;
    display: block;
    padding: 30px 0 0;
    justify-content: center;
}
.footer-container h5 {
    font-size: 25px;
    margin: 0;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif !important;
}
@media screen and (max-width: 899px) {
    .footer-container .footer-container {
        max-width: 500px !important;
    }
}

.footer {
    color: white;
    background: rgb(30, 0, 62);
    background: linear-gradient(190deg, rgb(30, 0, 62) 20%, rgb(112, 17, 167));
}
.footer h2 {
    font-size: 20px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}
.footer h3 {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    padding-right: 20px;
}
.footer .stores {
    width: 120px;
}
.footer ul {
    width: 100%;
    padding: 10px 0;
    display: block;
}
.footer ul li {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: 6px 0;
}
.footer .legales li {
    list-style-type: square;
}
.footer .contact {
    display: flex;
    align-items: center;
}
.footer .contact .data {
    display: flex;
    list-style-type: none;
}
.footer .contact .data img {
    width: 30px;
}
.footer .contact .data a {
    font-size: 17px;
    text-decoration: none;
    color: white;
}
.footer .contact .data a:hover {
    color: #fd45d9;
}
.footer .social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.21);
    padding-top: 0 !important;
    padding-bottom: 20px;
    margin-top: 40px;
}
.footer .social-media a img {
    width: 30px;
    margin-right: 20px;
}
.footer .social-media a img:hover {
    width: 25px;
    transition: 0.5s;
}
.footer .mapa li,
.footer .legales li,
.footer .aviso li {
    list-style-type: none;
    font-size: 15px;
}
.footer .mapa li a,
.footer .legales li a,
.footer .aviso li a {
    text-decoration: none !important;
    color: white !important;
}
.footer .mapa li a:hover,
.footer .legales li a:hover,
.footer .aviso li a:hover {
    color: #fd45d9 !important;
}
.footer .logo {
    width: 195px;
    height: auto;
    margin: left;
    display: block;
    padding-bottom: 15px;
}
.footer .item {
    margin-top: 60px;
    text-align: center;
}
.footer .bottom {
    text-align: center;
    background: rgb(30, 5, 56);
    align-items: center;
}
.footer .bottom p {
    font-size: 15px;
}
.footer .bottom button {
    background-color: transparent;
    color: white;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    border: solid 0px;
}
.footer .bottom span {
    font-size: 1.1rem;
}

/** ELEMENTS **/
.button {
    cursor: pointer;
    padding: 10px 20px !important;
    text-align: center !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif !important;
    color: #ffffff !important;
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(173, 39, 107) 0%,
        rgb(255, 40, 223) 100%
    ) !important;
    text-transform: none !important;
    border: none !important;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
.button.header-btn {
    margin-top: 20px;
    font-size: 22px !important;
    box-shadow:
        rgba(50, 50, 93, 0.35) 0px 30px 60px -12px,
        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px !important;
}

.button:hover {
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(147, 26, 87) 0%,
        rgb(238, 64, 212) 100%
    ) !important;
}
.button:hover.header-btn:hover {
    transition: 1s;
    transform: scale(1.2);
}

.svg {
    font-size: 17px !important;
    top: 50%;
    margin: 0 10px 0 0;
    vertical-align: text-bottom !important;
}

/*** HOME ***/
.active-plan,
.inactive-plan {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    padding: 10px 30px;
    margin: 50px 0;
    position: relative;
    display: flex;
    background-color: white;
}
.active-plan .plan-header,
.inactive-plan .plan-header {
    display: flex;
    align-items: flex-start;
    padding: 0 20px;
}
.active-plan .plan-header h2,
.inactive-plan .plan-header h2 {
    color: #7544ef;
    font-size: 26px;
    margin-bottom: 0 !important;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
.active-plan .plan-header .plan-dates p,
.inactive-plan .plan-header .plan-dates p {
    font-size: 15px;
    color: rgb(24, 24, 24);
    padding: 0;
}
.active-plan .plan-header .plan-dates p span,
.inactive-plan .plan-header .plan-dates p span {
    font-weight: 700;
    color: rgb(78, 78, 78);
    line-height: 25px;
}
.active-plan .inactive-text-container,
.inactive-plan .inactive-text-container {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 30px;
}
.active-plan .inactive-text-container h2,
.inactive-plan .inactive-text-container h2 {
    margin: 0;
    color: #7544ef;
}
.active-plan .inactive-text-container span,
.inactive-plan .inactive-text-container span {
    font-weight: 900;
    color: #fd45d9;
}
.active-plan .inactive-text-container button,
.inactive-plan .inactive-text-container button {
    width: 50%;
    margin: 20px 0;
}
.active-plan .inactive-image-container,
.inactive-plan .inactive-image-container {
    display: flex;
    justify-content: center;
}
.active-plan .inactive-image-container .inactive-image,
.inactive-plan .inactive-image-container .inactive-image {
    width: 50%;
}
@media screen and (max-width: 767px) {
    .active-plan .inactive-image-container,
    .inactive-plan .inactive-image-container {
        display: none;
    }
}
.active-plan .bags,
.inactive-plan .bags {
    text-align: center;
    padding: 10px 30px 30px;
}
.active-plan .bags h4,
.inactive-plan .bags h4 {
    font-size: 18px;
    z-index: 900 !important;
    position: relative;
}
.active-plan .bags .free,
.inactive-plan .bags .free {
    width: 60%;
    background-color: #f3f2fe;
    color: #050505;
    text-align: center;
    margin: 0 auto;
    padding: 3px 0;
    border-radius: 10px;
    margin-top: -30px;
    margin-bottom: 30px;
}
.active-plan .bags .detail,
.inactive-plan .bags .detail {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    padding: 0 20px 10px;
    margin: 0 auto;
    display: block;
}
.active-plan .bags .detail h5,
.inactive-plan .bags .detail h5 {
    text-align: left;
    font-size: 16px;
    color: #343434;
}
.active-plan .bags .chart-container,
.inactive-plan .bags .chart-container {
    margin: 0;
    top: -60px !important;
    position: relative;
}
.active-plan .bags .chart-container .chart,
.inactive-plan .bags .chart-container .chart {
    height: 260px;
}
.active-plan .bags .chart-container h3,
.inactive-plan .bags .chart-container h3 {
    font-size: 22px;
    margin: 0;
    color: #050505;
}
.active-plan .bags .chart-container h4,
.inactive-plan .bags .chart-container h4 {
    font-size: 18px;
    margin: 0;
    color: #050505;
}
.active-plan .bags .chart-container p,
.inactive-plan .bags .chart-container p {
    font-size: 16px;
    margin: 5px;
}
.active-plan .bags .chart-container .colored,
.inactive-plan .bags .chart-container .colored {
    color: #050505;
    font-weight: 900;
}
.active-plan .bags .chart-container .data,
.inactive-plan .bags .chart-container .data {
    z-index: 900;
}

/*** BOLSAS ***/
.products-tab-container {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 40px;
}
.products-tab-container .products-tab {
    border: 1px solid #cbc4cf;
    border-radius: 40px;
    padding: 2px;
}
.products-tab-container .products-tab .tab {
    padding: 2px 10px;
    text-transform: none;
    font-size: 25px;
    font-family: "Poppins-Medium", "Helvetica", "Arial", "sans-serif" !important;
}

.bag-container {
    display: flex;
    align-items: center;
}
.bag-container .bag-card {
    height: auto;
    border-radius: 25px;
    text-align: center;
    justify-content: center;
    position: relative;
    background: rgb(144, 205, 252);
    background: linear-gradient(
        34deg,
        rgb(144, 205, 252) 0%,
        rgb(133, 108, 243) 100%
    );
    color: white !important;
    border-bottom: 0;
    padding: 30px 0 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bag-container .bag-card .megabytes {
    font-size: 82px;
    text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bag-container .bag-card .megabytes p {
    margin: 0 !important;
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
    line-height: 80px;
    margin-bottom: -5px !important;
}
.bag-container .bag-card .megabytes p span {
    font-size: 23px !important;
}
.bag-container .bag-card .life {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin: 0 auto;
    display: block;
}
.bag-container .bag-card .life span {
    font-size: 40px;
}
.bag-container .bag-card .price p {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 50px;
    line-height: 60px;
}
.bag-container .bag-card .price p span {
    font-size: 30px;
}
.bag-container .bag-card .bag-footer {
    margin: 10px 0 5px;
}
.bag-container .bag-card .bag-footer .bag-button {
    height: 38px;
    margin: 0 auto;
    border: 0 solid;
    border-radius: 25px;
    padding: 3px 65px;
    font-size: 1.2em;
    color: white;
    text-transform: none !important;
    background-color: #f761ff;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 14px !important;
}
.bag-container .bag-card .bag-footer .bag-button:hover {
    transform: scale(1.2);
    transition: 0.8s;
}
.bag-container .bag-card .bag-footer .description {
    width: 80%;
    margin: 0 auto !important;
    padding: 15px 0 0;
}
.bag-container .bag-card .bag-footer .description small {
    line-height: 20px;
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
    font-size: 13px;
}
.bag-container a {
    text-decoration: none;
    padding: 15px 0 0;
    font-weight: bolder;
    font-size: 16px;
}

.bag-container-lite {
    display: flex;
    align-items: center;
}
.bag-container-lite .bag-card {
    height: auto;
    border-radius: 25px;
    background: linear-gradient(
        145deg,
        rgb(89, 16, 226) 0%,
        rgb(79, 3, 145) 100%
    );
    text-align: center;
    justify-content: center;
    position: relative;
    border-bottom: 0;
    color: white;
    padding: 10px 0 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bag-container-lite .bag-card .life {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    font-size: 30px;
    margin: 0 auto;
    display: block;
}
.bag-container-lite .bag-card .life span {
    font-size: 40px;
}
.bag-container-lite .bag-card .first-price p {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin: 0 0 3px;
}
.bag-container-lite .bag-card .first-price p span {
    font-size: 17px;
}
.bag-container-lite .bag-card .first-price p small {
    font-size: 14px;
}
.bag-container-lite .bag-card .first-price p s {
    display: inline-block;
    text-decoration: none;
    position: relative;
}
.bag-container-lite .bag-card .first-price p s:after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    color: #f761ff;
    border-bottom: 2px solid;
}
.bag-container-lite .bag-card .price p {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 62px;
    line-height: 60px;
}
.bag-container-lite .bag-card .price p span {
    font-size: 35px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.bag-container-lite .bag-card .price p small {
    font-size: 18px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.bag-container-lite .bag-card .price p sup {
    font-size: 18px;
}
.bag-container-lite .bag-card .share-data p {
    font-size: 14px;
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif !important;
    margin: 0;
}
.bag-container-lite .bag-card .share-data p img {
    height: 15px;
    margin-right: 10px;
}
.bag-container-lite .bag-card .bag-footer {
    margin: 10px 0 5px;
}
.bag-container-lite .bag-card .bag-footer .bag-button {
    height: 38px;
    margin: 0 auto;
    border: 0 solid;
    border-radius: 25px;
    padding: 3px 65px;
    font-size: 1.2em;
    color: white;
    text-transform: none !important;
    background: #f761ff;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 14px !important;
}
.bag-container-lite .bag-card .bag-footer .bag-button:hover {
    transform: scale(1.2);
    transition: 0.8s;
}
.bag-container-lite .bag-card .bag-footer .description {
    width: 80%;
    margin: 0 auto !important;
    padding: 15px 0 0;
}
.bag-container-lite .bag-card .bag-footer .description small {
    line-height: 20px;
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
    font-size: 12px;
}
.bag-container-lite a {
    text-decoration: none;
    padding: 15px 0 0;
    font-weight: bolder;
    font-size: 16px;
}

.bag-container-classic {
    display: flex;
    align-items: center;
}
.bag-container-classic .bag-card {
    height: auto;
    border-radius: 25px;
    text-align: center;
    justify-content: center;
    position: relative;
    background: linear-gradient(
        145deg,
        rgb(61, 39, 112) 0%,
        rgb(167, 1, 233) 100%
    );
    color: white !important;
    border-bottom: 0;
    padding: 10px 0 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bag-container-classic .bag-card .life {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    font-size: 30px;
    margin: 0 auto;
    display: block;
}
.bag-container-classic .bag-card .life span {
    font-size: 40px;
}
.bag-container-classic .bag-card .first-price p {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
    margin: 0 0 3px;
    font-size: 32px;
}
.bag-container-classic .bag-card .first-price p span {
    font-size: 17px;
}
.bag-container-classic .bag-card .first-price p small {
    font-size: 14px;
}
.bag-container-classic .bag-card .first-price p s {
    display: inline-block;
    text-decoration: none;
    position: relative;
}
.bag-container-classic .bag-card .first-price p s:after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    color: #f761ff;
    border-bottom: 2px solid;
}
.bag-container-classic .bag-card .price p {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 62px;
    line-height: 60px;
}
.bag-container-classic .bag-card .price p span {
    font-size: 35px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.bag-container-classic .bag-card .price p small {
    font-size: 18px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.bag-container-classic .bag-card .price p sup {
    font-size: 18px;
}
.bag-container-classic .bag-card .share-data p {
    font-size: 14px;
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif !important;
    margin: 0;
}
.bag-container-classic .bag-card .share-data p img {
    height: 15px;
    margin-right: 10px;
}
.bag-container-classic .bag-card .bag-footer {
    margin: 10px 0 5px;
}
.bag-container-classic .bag-card .bag-footer .bag-button {
    height: 38px;
    margin: 0 auto;
    border: 0 solid;
    border-radius: 25px;
    padding: 3px 65px;
    font-size: 1.2em;
    color: white;
    text-transform: none !important;
    background: #f761ff;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 14px !important;
}
.bag-container-classic .bag-card .bag-footer .bag-button:hover {
    transform: scale(1.2);
    transition: 0.8s;
}
.bag-container-classic .bag-card .bag-footer .description {
    width: 80%;
    margin: 0 auto !important;
    padding: 15px 0 0;
}
.bag-container-classic .bag-card .bag-footer .description small {
    line-height: 20px;
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
    font-size: 12px;
}
.bag-container-classic a {
    text-decoration: none;
    padding: 15px 0 0;
    font-weight: bolder;
    font-size: 16px;
}

.bag-container-plus {
    display: flex;
    align-items: center;
}
.bag-container-plus .bag-card {
    height: auto;
    border-radius: 25px;
    background: linear-gradient(
        145deg,
        rgb(28, 9, 92) 0%,
        rgb(58, 9, 105) 100%
    );
    text-align: center;
    justify-content: center;
    position: relative;
    border-bottom: 0;
    color: white;
    padding: 10px 0 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bag-container-plus .bag-card .life {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    font-size: 30px;
    margin: 0 auto;
    display: block;
}
.bag-container-plus .bag-card .life span {
    font-size: 40px;
}
.bag-container-plus .bag-card .first-price p {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin: 0 0 3px;
}
.bag-container-plus .bag-card .first-price p span {
    font-size: 17px;
}
.bag-container-plus .bag-card .first-price p small {
    font-size: 14px;
}
.bag-container-plus .bag-card .first-price p s {
    display: inline-block;
    text-decoration: none;
    position: relative;
}
.bag-container-plus .bag-card .first-price p s:after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    color: #f761ff;
    border-bottom: 2px solid;
}
.bag-container-plus .bag-card .price p {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    margin: 0;
    font-size: 62px;
    line-height: 60px;
}
.bag-container-plus .bag-card .price p span {
    font-size: 35px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.bag-container-plus .bag-card .price p small {
    font-size: 18px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.bag-container-plus .bag-card .price p sup {
    font-size: 18px;
}
.bag-container-plus .bag-card .share-data p {
    font-size: 14px;
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif !important;
    margin: 0;
}
.bag-container-plus .bag-card .share-data p img {
    height: 15px;
    margin-right: 10px;
}
.bag-container-plus .bag-card .bag-footer {
    margin: 10px 0 5px;
}
.bag-container-plus .bag-card .bag-footer .bag-button {
    height: 38px;
    margin: 0 auto;
    border: 0 solid;
    border-radius: 25px;
    padding: 3px 65px;
    font-size: 1.2em;
    color: white;
    text-transform: none !important;
    background: #f761ff;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 14px !important;
}
.bag-container-plus .bag-card .bag-footer .bag-button:hover {
    transform: scale(1.2);
    transition: 0.8s;
}
.bag-container-plus .bag-card .bag-footer .description {
    width: 80%;
    margin: 0 auto !important;
    padding: 15px 0 0;
}
.bag-container-plus .bag-card .bag-footer .description small {
    line-height: 20px;
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
    font-size: 12px;
}
.bag-container-plus a {
    text-decoration: none;
    padding: 15px 0 0;
    font-weight: bolder;
    font-size: 16px;
}

.introduction-container {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
}
.introduction-container .introduction {
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: rgb(34, 3, 91);
    background: linear-gradient(
        180deg,
        rgb(34, 3, 91) 0%,
        rgb(93, 7, 154) 100%
    );
}
.introduction-container .introduction h1 {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    color: white;
    font-size: 18px;
    margin: 0;
}

.prepaid-share-data {
    color: #fd45d9;
}

.data-wrapper {
    justify-content: center;
    margin: 75px 0 50px;
}

.data-container {
    font-family: "Poppins-regular";
    text-align: center;
    max-width: 970px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
.data-container.purchase-data-container {
    background: linear-gradient(
        174deg,
        rgb(76, 12, 111) 0%,
        rgb(15, 35, 119) 100%
    ) !important;
    border: none;
    color: white !important;
}
.data-container.purchase-data-container .line {
    height: 0.5px;
    background: #704ca1;
    max-width: 750px;
    width: 100% !important;
    margin: 0;
}
.data-container .line {
    height: 0.5px;
    background: #26064e;
    max-width: 750px;
    width: 100% !important;
    margin: 0;
}
.data-container h2 {
    font-size: 25px;
    margin: 0;
    margin-bottom: 5px !important;
}
.data-container .black-italic {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}
.data-container .data-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.data-container .data-content h1 {
    font-size: 40px;
    margin: 10px 0;
}
.data-container .data-content .megabytes {
    display: flex;
    justify-content: center;
}
.data-container .data-content .megabytes img {
    height: 30px;
    fill: #29b77b;
}
.data-container .data-content .cobertura {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}
.data-container .data-content .cobertura img {
    height: 30px;
    margin-right: 13px;
}
.data-container .data-content .cobertura p {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
.data-container .data-content .cobertura span {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 15px;
}
.data-container .data-content .llamadas {
    text-align: left;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}
.data-container .data-content .llamadas img {
    height: 30px;
    margin-right: 13px;
}
.data-container .data-content .llamadas p {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
}
.data-container .data-content .llamadas span {
    font-size: 19px;
}
.data-container .data-content .comparte {
    text-align: left;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}
.data-container .data-content .comparte img {
    height: 30px;
    margin-right: 13px;
    color: #21024b !important;
}
.data-container .data-content .comparte p {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
}
.data-container .data-content .comparte span {
    padding-top: 7px;
    font-size: 19px;
}

.social-container {
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    max-width: 600px !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    color: white;
    background-color: #26064e;
}
.social-container h3 {
    margin: 10px 20px 10px 0;
    font-size: 18px;
}
.social-container img {
    height: 27px;
    color: white;
}
.social-container.purchase-social-container {
    background-color: #1c2077;
}

@media screen and (max-width: 899px) {
    .data-container {
        border-radius: 15px 15px 0 0;
    }
    .data-container .line {
        max-width: 310px;
    }
    .data-container .cobertura {
        display: block;
        margin: 0 auto;
        margin-bottom: 20px !important;
    }
    .data-container .comparte {
        margin: 20px 0 !important;
    }
    .social-container h3 {
        margin: 20px auto;
        margin-left: 0 !important;
    }
}
@media screen and (max-width: 767px) {
    .social-container h3 {
        margin: 20px auto !important;
        text-align: center !important;
    }
}
.social-container-lite {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
}
.social-container-lite .social-media {
    border-radius: 0px 0px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
}
.social-container-lite .social-media img {
    height: 38px;
    color: white;
}

.no-planes-box {
    margin: 30px 0 0;
    padding: 0 !important;
}
.no-planes-box .no-planes {
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-planes-box .no-planes.img-1 img {
    width: 64%;
}
.no-planes-box .no-planes.img-2 img {
    width: 100%;
}
@media only screen and (max-width: 899px) {
    .no-planes-box .no-planes.img-1 img {
        width: 60%;
    }
    .no-planes-box .no-planes.img-2 img {
        width: 90%;
    }
}

.products-leyend {
    display: flex;
    margin: 40px 0 20px !important;
    justify-content: center;
    font-size: 20px;
    text-align: center;
}
.products-leyend.purchase {
    color: white !important;
}
.products-leyend a {
    margin: 0 8px !important;
}

.recommended.bag-content .bag-card {
    padding: 25px;
    border: 7.5px solid #fd45d9;
}

.recommended-container {
    position: relative;
}
.recommended-container .recommended-label {
    display: block;
    position: absolute;
    z-index: 9;
    background: #fd45d9;
    border-radius: 5px;
    padding: 10px;
    top: -20px;
    left: 25%;
    right: 25%;
    color: white;
    font-weight: 600;
    font-style: italic;
    text-align: center;
}

.recommended.mobile {
    display: none;
}

@media screen and (max-width: 899px) {
    .recommended {
        display: none;
    }
    .recommended.mobile {
        display: block;
    }
}
.accordion {
    border-radius: 10px;
    padding: 0 20px 70px;
    border-bottom: none;
    max-width: 1000px;
    flex-wrap: wrap;
    margin: 0 auto;
}
.accordion h1 {
    text-align: center;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
    font-size: 1.5em;
    color: #51328b;
}
.accordion .row {
    height: 60px;
    background-color: #fefefe;
    border-bottom: 1px solid #ddd;
    box-shadow: none;
}
.accordion .row .accordion-title {
    font-size: 19px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    color: #51318f;
}
.accordion .row .accordion-title:focus {
    font-size: 25px;
    color: pink;
}
.accordion.accordion:not(.collapsed) {
    font-size: 1.3rem !important;
    color: #df1bc0 !important;
    background-color: #fff !important;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.13);
    font-family: "Poppins-Semibold", "Helvetica", "Arial", "sans-serif" !important;
}

/* PAYMENT FORM */
.payment-container {
    padding-top: 20px !important;
    margin: 0 auto !important;
    display: flex !important;
    align-content: center;
    flex-wrap: nowrap !important;
    max-width: 1200px;
}
.payment-container .hide {
    display: none;
}
@media only screen and (max-width: 899px) {
    .payment-container .hide-card {
        display: none;
    }
}

.resume {
    padding: 0;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    margin: 30px 0;
}
.resume .head {
    color: white;
    background-color: #400295;
    text-align: left;
    font-size: 19px;
    font-family: "Poppins-Medium";
    padding: 5px 20px;
    line-height: 0;
    border-radius: 15px 15px 0 0;
    width: 100%;
}
.resume .info {
    padding: 0 20px;
    display: flex;
    align-items: center;
}
.resume .info .rows {
    padding: 0;
}
.resume .info p {
    font-weight: bold;
    font-size: 18px;
}
.resume .total {
    background-color: #faf8ff;
    border-radius: 0 0 15px 15px;
    color: #7544ef;
    font-weight: bold;
    padding: 0 20px;
    border-top: solid 1px #ebebeb;
}
.resume .total span {
    font-size: 23px;
}

.payment-form {
    width: auto !important;
    margin: 0 auto !important;
}
.payment-form .section {
    padding: 10px 30px 30px;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
}
.payment-form .section .title-section {
    display: flex;
    align-items: center;
}
.payment-form .section .title-section img {
    height: 25px;
    fill: #7544ef;
    margin-right: 20px;
}
.payment-form .section .instruction {
    margin: 15px 0 5px;
}
.payment-form .section h5 {
    color: #7544ef;
    font-size: 25px;
    margin: 10px 0;
}

.amex-form,
.invoice-form {
    display: flex;
}
.amex-form.amex-purchase,
.invoice-form.amex-purchase {
    background: #f9f5ff;
    padding: 10px 20px 0;
    border-radius: 10px;
    border: 1px solid #dfcccc;
    margin-bottom: 20px;
}
.amex-form.amex-purchase h5,
.invoice-form.amex-purchase h5 {
    color: #6b50af;
    font-size: 20px;
}
.amex-form.amex-purchase .payment-amex input,
.invoice-form.amex-purchase .payment-amex input {
    height: 55px;
    width: 90%;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    padding: 0 15px;
    font-size: 16px;
    margin-bottom: 0 !important;
}
.amex-form.amex-purchase .payment-amex label,
.invoice-form.amex-purchase .payment-amex label {
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    color: #5a5a5a;
}
.amex-form h6,
.invoice-form h6 {
    color: #3c3c3c;
    font-weight: 400;
    font-size: 15px;
    margin: 0 0 15px;
}
.amex-form h5,
.invoice-form h5 {
    color: #7544ef;
    font-size: 25px;
    margin: 10px 0;
}
.amex-form span,
.invoice-form span {
    font-size: 15x;
    color: #3c3c3c;
    font-weight: 400;
    margin: 5px 0;
}
.amex-form .amex,
.invoice-form .amex {
    flex-basis: 100%;
    margin: 10px 0;
}

.payment-input {
    margin: 20px 0 !important;
}
.payment-input input {
    margin-top: 8px;
    height: 55px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    padding: 0 15px;
    font-size: 16px;
}
.payment-input input:hover {
    border: 1px solid #441556 !important;
}
.payment-input label {
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    color: #5a5a5a;
}

.labels input {
    margin-top: 8px;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    padding: 0 15px;
    font-size: 16px;
}
.labels input:hover {
    border: 1px solid #441556 !important;
}
.labels label {
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    color: #5a5a5a;
}

.label-dropdown .label {
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    color: #5a5a5a;
}
.label-dropdown.label-dropdown:hover {
    border: 1px solid #441556 !important;
}

.email-box {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    padding: 25px 20px 10px;
    margin: 10px 0;
    border-radius: 10px;
}
.email-box p {
    margin: 0;
}

.payment-button {
    cursor: pointer;
    width: 40% !important;
    margin: 0 auto !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 50px !important;
    padding: 10px 20px !important;
    text-align: center !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif !important;
    color: #ffffff !important;
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(173, 39, 107) 0%,
        rgb(221, 57, 188) 100%
    ) !important;
    text-transform: none !important;
    border: none !important;
}
.payment-button.header-btn {
    font-size: 22px !important;
}

.payment-button:hover {
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        #971440 0%,
        rgb(221, 57, 188) 100%
    ) !important;
    transition: 0.2s;
}

.form-control {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: purple !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(75, 6, 66, 0.25);
}

.text-align-right h3 {
    text-align: right;
}

.response-message {
    padding: 40px 0 50px;
    align-items: center;
    margin: 0 auto !important;
    text-align: center;
}
.response-message .image {
    margin-top: 20px;
    width: 12%;
    height: auto;
}
@media screen and (max-width: 767px) {
    .response-message .image {
        width: 32%;
    }
}
.response-message .error-image {
    width: 12%;
    height: auto;
}
@media screen and (max-width: 767px) {
    .response-message .error-image {
        width: 25%;
    }
}
.response-message h1 {
    font-size: 2rem;
    margin: 40px 0 0;
    color: #3d3d3d;
    font-family: "Poppins-Medium", "Helvetica", "Arial", "sans-serif" !important;
}
.response-message.success h1 {
    color: #29cd98;
}
.response-message.error h1 {
    color: #e02c2c;
}
.response-message.error h3 {
    margin: 10px 0 40px;
    font-weight: 500;
    font-size: 17px;
}

.transaction-detail {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    color: #3d3d3d;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    background-color: white;
    -webkit-box-shadow: 0px 5px 5px -2px #c4c4c4;
    box-shadow: 0px 5px 5px -2px #c4c4c4;
    font-family: "NotoSans-Medium", "Helvetica", "Arial", "sans-serif" !important;
}
.transaction-detail .transaction-title {
    background-color: #f7f5fb;
    padding: 5px 20px;
}
.transaction-detail .transaction-title h3 {
    font-size: 1rem;
    font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
}
.transaction-detail .transaction-section {
    margin: 0 20px;
}
.transaction-detail .transaction-section .label {
    text-align: left;
    font-size: 16px;
    font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
}
.transaction-detail .transaction-section .response p {
    font-family: "Poppins-medium";
    text-align: right;
    font-size: 18px;
}
.transaction-detail .transaction-section.last {
    margin-bottom: 5px;
}

.action-button {
    background-color: #ff28dd !important;
    border: none !important;
    color: white;
    width: 190px;
    margin: 0 auto;
    border: 0 solid;
    border-radius: 40px !important;
    padding: 15px;
    font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
}

@media only screen and (max-width: 1070px) {
    .header-container {
        padding: 30px;
    }
}
/* SHOPPING HISTORY */
.history-header {
    width: 100%;
    padding-top: 10px;
}
.history-header .history-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.history-header .history-text h1 {
    font-size: 38px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    text-align: center;
    letter-spacing: 1px;
    min-height: 95px;
}
.history-header .history-text h1 span {
    color: #a361ff;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.history-header .history-text p {
    font-size: 22px;
}
.history-header .history-text p span {
    color: #a361ff;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 899px) {
    .history-header .history-text {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
    }
    .history-header .history-text h1 {
        margin-top: 10px;
        font-size: 28px;
        font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    }
    .history-header .history-text p {
        font-size: 19px;
        margin: 0;
        line-height: 26px !important;
    }
    .history-header .history-text p span {
        color: #a361ff;
        font-size: 31px;
        font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    }
}

/*** PAYMENT ***/
.card-box {
    background: rgb(120, 18, 191);
    background: radial-gradient(
        circle,
        rgb(136, 23, 216) 0%,
        rgb(62, 6, 126) 100%
    );
    padding: 40px 0;
}

.card-form {
    min-width: 100% !important;
    display: flex;
    justify-content: center;
    padding: 40px 0;
}

/* MODAL */
.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(90, 87, 130, 0.1);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9;
}

.modalContainer {
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modalContainer .title {
    display: inline-block;
    text-align: left;
    margin-top: 10px;
}
.modalContainer .title h3 {
    color: #441556;
    margin: 3px 10px !important;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif !important;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    color: #aea5a5;
    border: none;
}

.modalContainer .body {
    flex: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
}

.modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: #7544ef;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
}

#cancelBtn {
    background-color: transparent;
    color: #7544ef;
}

.center_circular_progress {
    display: flex;
    justify-content: center;
    margin: 60px 0;
}

.elementHidden {
    display: none !important;
}

.required-incidence {
    margin: 0 !important;
}

.error-response-container {
    margin-top: 5px !important;
}

.error-response {
    margin-bottom: 5px !important;
}

.left-payment-options-dropdown {
    text-align: left;
}

.right-payment-options-dropdown {
    text-align: right;
}

.monthly-payment-dropdown #demo-simple-select {
    display: flex;
}

.add-card-section.hide-add-card-section {
    display: none;
}

.cards-section .MuiFormControl-root {
    width: 100%;
}
.cards-section .MuiFormControl-root .card-section {
    margin: 10px 0;
    padding: 15px 0;
}

.card-element .card-checkbox label {
    margin: 0;
}
.card-element .card-checkbox,
.card-element .card-image {
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-element .card-checkbox img,
.card-element .card-image img {
    max-height: 30px;
}
.card-element .card-name,
.card-element .card-number {
    display: flex;
    justify-content: start;
    align-items: center;
}
.card-element .card-name p,
.card-element .card-number p {
    margin: 0;
}
.card-element .card-name p {
    font-family: "NotoSans-SemiBold", "Helvetica", "Arial", "sans-serif" !important;
}
.card-element .card-number p {
    font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
}

.payment-container {
    width: 100% !important;
}

.portability-header {
    width: 100%;
    padding-top: 10px;
}
.portability-header .portability-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.portability-header .portability-text h1 {
    font-size: 38px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    margin-bottom: 40px;
    text-align: center;
    letter-spacing: 1px;
    min-height: 95px;
}
.portability-header .portability-text h1 span {
    color: #a361ff;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 899px) {
    .portability-header .portability-text {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
    }
    .portability-header .portability-text h1 {
        margin-top: 10px;
        font-size: 28px;
        font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    }
    .portability-header .portability-text p {
        font-size: 19px;
        margin: 0;
        line-height: 26px !important;
    }
    .portability-header .portability-text p span {
        color: #a361ff;
        font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    }
}

.portability-image-container {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.portability-image {
    padding: 0;
    width: 78%;
}

.clase-del-boton {
    padding-bottom: 50px;
}
.clase-del-boton button {
    max-height: 50px;
    margin-top: 10px;
}
.clase-del-boton button h4 {
    font-size: 17px;
    letter-spacing: 1px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.portability-container {
    background: #fbfdff;
    padding: 10px 0 40px;
}

.portability-form-container {
    width: 100% !important;
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
}
.portability-form-container h2 {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    color: #51328b;
    font-size: 24px;
}

.portability-text p {
    margin: 0;
    text-align: center;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    color: #2f2b31;
}

.portability-text.success-response {
    margin: 30px 0;
    text-align: center;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #2f2b31;
}

.portability-form-content {
    border: 1px solid #d4d4d4;
    border-radius: 15px;
    margin: 35px auto 25px !important;
    padding: 35px 40px 20px;
}
.portability-form-content .portability-instruction {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.portability-form-content .portability-instruction p {
    font-size: 16px;
    line-height: 30px;
    margin: 0px;
}
.portability-form-content .form-input {
    margin: 20px 0 30px !important;
}

.portability-form-content .pink-number {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    color: #ff28dd;
    border: 3px solid #fd45d9;
    border-radius: 100%;
    margin-right: 15px;
    padding: 0 10px;
    width: 30px;
    height: 30px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.pink-text {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    color: #ff28dd;
}

@media (max-width: 1199px) {
    .portability-image {
        display: none;
    }
    .clase-del-boton {
        padding-bottom: 0;
    }
}
.response-container.error {
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}
.response-container .response-title,
.response-container .response-text {
    font-size: 14px;
}
.response-container .response-title {
    margin-bottom: 10px;
}
.response-container .response-text {
    margin-top: 10px;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.portability-title {
    padding: 30px 0 0;
    text-align: center;
}
.portability-title h3 {
    color: #51328b;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 32px;
}

.portability-check {
    display: flex;
    justify-content: center;
}
.portability-check svg {
    font-size: 55px !important;
    color: #6d39ef;
}

.error-response-button {
    margin-top: 30px;
    border: none;
    background: none;
    color: #fd45d9;
    font-size: 16px;
    font-family: Arial;
    cursor: pointer;
}

.modal-error-icon {
    color: #d13852;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

#modal-modal-title {
    text-align: center;
    display: flex;
    justify-content: center;
}
#modal-modal-title .modal-error-title {
    font-size: 20px;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

/***************************/
/* My Cards / Mis Tarjetas */
.my-cards-container,
.my-legals-container {
    margin: 0;
}

.my-cards-header,
.my-legals-header {
    justify-content: center;
}

.my-cards-title,
.my-legals-title {
    text-align: center;
}
.my-cards-title h3,
.my-legals-title h3 {
    margin-top: 10px;
    margin-bottom: 65px;
    font-size: 35px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    color: white;
}
.my-cards-title h3 span,
.my-legals-title h3 span {
    color: #a361ff;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.my-legals-wrapper,
.my-cards-wrapper {
    justify-content: center;
    margin-top: 20px;
}

.centered {
    display: flex;
    justify-content: center;
}

.my-cards-content,
.my-legals-content {
    display: flex !important;
    justify-content: center !important;
    margin-top: 15px;
}

.my-cards-form-container,
.my-legals-form-container {
    justify-content: left;
    padding: 0 25px 50px !important;
}
.my-cards-form-container h1,
.my-legals-form-container h1 {
    margin: 0 0 35px;
    color: #26202f;
    text-align: center;
    font-size: 19px;
    font-weight: 500;
}
.my-cards-form-container .my-cards-form,
.my-cards-form-container .my-legals-form,
.my-legals-form-container .my-cards-form,
.my-legals-form-container .my-legals-form {
    background: #f9f9fc;
    border-radius: 15px;
    max-width: 550px;
    padding: 50px;
    border: 1px solid #fafaff;
}
.my-cards-form-container .my-cards-form .data-container .data-item,
.my-cards-form-container .my-legals-form .data-container .data-item,
.my-legals-form-container .my-cards-form .data-container .data-item,
.my-legals-form-container .my-legals-form .data-container .data-item {
    margin-bottom: 20px;
}
.my-cards-form-container .my-cards-form .data-container .data-item.centered,
.my-cards-form-container .my-legals-form .data-container .data-item.centered,
.my-legals-form-container .my-cards-form .data-container .data-item.centered,
.my-legals-form-container .my-legals-form .data-container .data-item.centered {
    display: flex;
    justify-content: center;
}
.my-cards-form-container .my-cards-form .data-container .data-item label,
.my-cards-form-container .my-legals-form .data-container .data-item label,
.my-legals-form-container .my-cards-form .data-container .data-item label,
.my-legals-form-container .my-legals-form .data-container .data-item label {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
    margin-bottom: 20px;
}
.my-cards-form-container .my-cards-form .data-container .data-item .m-tb-20,
.my-cards-form-container .my-legals-form .data-container .data-item .m-tb-20,
.my-legals-form-container .my-cards-form .data-container .data-item .m-tb-20,
.my-legals-form-container .my-legals-form .data-container .data-item .m-tb-20 {
    margin: 10px 0 5px !important;
}
.my-cards-form-container
    .my-cards-form
    .data-container
    .data-item
    .required-incidence.m-0,
.my-cards-form-container
    .my-legals-form
    .data-container
    .data-item
    .required-incidence.m-0,
.my-legals-form-container
    .my-cards-form
    .data-container
    .data-item
    .required-incidence.m-0,
.my-legals-form-container
    .my-legals-form
    .data-container
    .data-item
    .required-incidence.m-0 {
    margin: 0 0 15px !important;
}
.my-cards-form-container .my-cards-form .data-container .data-item .data-input,
.my-cards-form-container .my-legals-form .data-container .data-item .data-input,
.my-legals-form-container .my-cards-form .data-container .data-item .data-input,
.my-legals-form-container
    .my-legals-form
    .data-container
    .data-item
    .data-input {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
    background-color: #ffffff;
}
.my-cards-form-container .my-cards-form .button,
.my-cards-form-container .my-legals-form .button,
.my-legals-form-container .my-cards-form .button,
.my-legals-form-container .my-legals-form .button {
    padding: 10px 40px !important;
}
.my-cards-form-container .my-cards-form,
.my-cards-form-container .my-legals-form,
.my-legals-form-container .my-cards-form,
.my-legals-form-container .my-legals-form {
    padding: 30px 20px 20px;
    margin: 0 auto;
}

.my-cards-items,
.my-legals-items {
    display: flex;
    justify-content: flex-start;
    flex-direction: column !important;
    align-items: center;
}
.my-cards-items .my-cards-item,
.my-legals-items .my-cards-item {
    width: 75% !important;
}
.my-cards-items .my-legals-item,
.my-legals-items .my-legals-item {
    width: 85% !important;
    display: flex;
}
.my-cards-items .my-cards-item,
.my-cards-items .my-legals-item,
.my-legals-items .my-cards-item,
.my-legals-items .my-legals-item {
    margin: 0 0 15px;
    padding: 15px 25px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    transition: all 2s linear 1s;
}
.my-cards-items .my-cards-item.selected,
.my-cards-items .my-legals-item.selected,
.my-legals-items .my-cards-item.selected,
.my-legals-items .my-legals-item.selected {
    background-color: #ffffff;
    border: 3px solid #3900a8;
}
.my-cards-items .my-cards-item.selected .my-cards-item-text,
.my-cards-items .my-cards-item.selected .my-legals-item-text,
.my-cards-items .my-legals-item.selected .my-cards-item-text,
.my-cards-items .my-legals-item.selected .my-legals-item-text,
.my-legals-items .my-cards-item.selected .my-cards-item-text,
.my-legals-items .my-cards-item.selected .my-legals-item-text,
.my-legals-items .my-legals-item.selected .my-cards-item-text,
.my-legals-items .my-legals-item.selected .my-legals-item-text {
    color: #3900a8;
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    text-align: right;
}
.my-cards-items .my-cards-item.selected .my-cards-item-image svg,
.my-cards-items .my-cards-item.selected .my-legals-item-image svg,
.my-cards-items .my-legals-item.selected .my-cards-item-image svg,
.my-cards-items .my-legals-item.selected .my-legals-item-image svg,
.my-legals-items .my-cards-item.selected .my-cards-item-image svg,
.my-legals-items .my-cards-item.selected .my-legals-item-image svg,
.my-legals-items .my-legals-item.selected .my-cards-item-image svg,
.my-legals-items .my-legals-item.selected .my-legals-item-image svg {
    fill: #3900a8;
}
.my-cards-items .my-cards-item .my-cards-item-image,
.my-cards-items .my-cards-item .my-legals-item-image,
.my-cards-items .my-legals-item .my-cards-item-image,
.my-cards-items .my-legals-item .my-legals-item-image,
.my-legals-items .my-cards-item .my-cards-item-image,
.my-legals-items .my-cards-item .my-legals-item-image,
.my-legals-items .my-legals-item .my-cards-item-image,
.my-legals-items .my-legals-item .my-legals-item-image {
    display: flex;
}
.my-cards-items .my-cards-item .my-cards-item-image img,
.my-cards-items .my-cards-item .my-legals-item-image img,
.my-cards-items .my-legals-item .my-cards-item-image img,
.my-cards-items .my-legals-item .my-legals-item-image img,
.my-legals-items .my-cards-item .my-cards-item-image img,
.my-legals-items .my-cards-item .my-legals-item-image img,
.my-legals-items .my-legals-item .my-cards-item-image img,
.my-legals-items .my-legals-item .my-legals-item-image img {
    width: 100%;
}
.my-cards-items .my-cards-item .my-cards-item-image.add-icon img,
.my-cards-items .my-cards-item .my-legals-item-image.add-icon img,
.my-cards-items .my-legals-item .my-cards-item-image.add-icon img,
.my-cards-items .my-legals-item .my-legals-item-image.add-icon img,
.my-legals-items .my-cards-item .my-cards-item-image.add-icon img,
.my-legals-items .my-cards-item .my-legals-item-image.add-icon img,
.my-legals-items .my-legals-item .my-cards-item-image.add-icon img,
.my-legals-items .my-legals-item .my-legals-item-image.add-icon img {
    width: 75%;
    max-width: 25px;
}
.my-cards-items .my-cards-item .my-cards-item-text,
.my-cards-items .my-cards-item .my-legals-item-text,
.my-cards-items .my-legals-item .my-cards-item-text,
.my-cards-items .my-legals-item .my-legals-item-text,
.my-legals-items .my-cards-item .my-cards-item-text,
.my-legals-items .my-cards-item .my-legals-item-text,
.my-legals-items .my-legals-item .my-cards-item-text,
.my-legals-items .my-legals-item .my-legals-item-text {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    color: #3d3d3d;
}
.my-cards-items .my-cards-item .my-cards-item-text.first,
.my-cards-items .my-cards-item .my-legals-item-text.first,
.my-cards-items .my-legals-item .my-cards-item-text.first,
.my-cards-items .my-legals-item .my-legals-item-text.first,
.my-legals-items .my-cards-item .my-cards-item-text.first,
.my-legals-items .my-cards-item .my-legals-item-text.first,
.my-legals-items .my-legals-item .my-cards-item-text.first,
.my-legals-items .my-legals-item .my-legals-item-text.first {
    display: flex;
}
.my-cards-items .my-cards-item .my-cards-item-text.third,
.my-cards-items .my-cards-item .my-legals-item-text.third,
.my-cards-items .my-legals-item .my-cards-item-text.third,
.my-cards-items .my-legals-item .my-legals-item-text.third,
.my-legals-items .my-cards-item .my-cards-item-text.third,
.my-legals-items .my-cards-item .my-legals-item-text.third,
.my-legals-items .my-legals-item .my-cards-item-text.third,
.my-legals-items .my-legals-item .my-legals-item-text.third {
    text-align: right;
}

.selected-card-wrapper,
.selected-legal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.selected-card-wrapper .selected-card-container,
.selected-card-wrapper .selected-legal-container,
.selected-legal-wrapper .selected-card-container,
.selected-legal-wrapper .selected-legal-container {
    background: rgb(194, 27, 200);
    background: linear-gradient(
        180deg,
        rgb(194, 27, 200) 0%,
        rgb(65, 42, 130) 100%
    );
    box-shadow: rgba(16, 9, 23, 0.35) 0px 25px 50px -12px;
    border-radius: 15px;
    max-width: 440px;
    max-height: 220px;
    padding: 35px;
}
.selected-card-wrapper .selected-card-container .selected-card-content,
.selected-card-wrapper .selected-card-container .selected-legal-content,
.selected-card-wrapper .selected-legal-container .selected-card-content,
.selected-card-wrapper .selected-legal-container .selected-legal-content,
.selected-legal-wrapper .selected-card-container .selected-card-content,
.selected-legal-wrapper .selected-card-container .selected-legal-content,
.selected-legal-wrapper .selected-legal-container .selected-card-content,
.selected-legal-wrapper .selected-legal-container .selected-legal-content {
    justify-content: space-between;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
}
.selected-card-wrapper .selected-card-container .selected-card-content img,
.selected-card-wrapper .selected-card-container .selected-legal-content img,
.selected-card-wrapper .selected-legal-container .selected-card-content img,
.selected-card-wrapper .selected-legal-container .selected-legal-content img,
.selected-legal-wrapper .selected-card-container .selected-card-content img,
.selected-legal-wrapper .selected-card-container .selected-legal-content img,
.selected-legal-wrapper .selected-legal-container .selected-card-content img,
.selected-legal-wrapper .selected-legal-container .selected-legal-content img {
    width: 100%;
    height: auto;
    max-width: 75px;
}
.selected-card-wrapper .selected-card-container .selected-card-content svg,
.selected-card-wrapper .selected-card-container .selected-legal-content svg,
.selected-card-wrapper .selected-legal-container .selected-card-content svg,
.selected-card-wrapper .selected-legal-container .selected-legal-content svg,
.selected-legal-wrapper .selected-card-container .selected-card-content svg,
.selected-legal-wrapper .selected-card-container .selected-legal-content svg,
.selected-legal-wrapper .selected-legal-container .selected-card-content svg,
.selected-legal-wrapper .selected-legal-container .selected-legal-content svg {
    font-size: 16px;
    margin-right: 5px;
}
.selected-card-wrapper .selected-card-container .selected-card-content button,
.selected-card-wrapper .selected-card-container .selected-legal-content button,
.selected-card-wrapper .selected-legal-container .selected-card-content button,
.selected-card-wrapper .selected-legal-container .selected-legal-content button,
.selected-legal-wrapper .selected-card-container .selected-card-content button,
.selected-legal-wrapper .selected-card-container .selected-legal-content button,
.selected-legal-wrapper .selected-legal-container .selected-card-content button,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    button {
    color: #ffffff;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 0;
}
.selected-card-wrapper
    .selected-card-container
    .selected-card-content
    .selected-card-text,
.selected-card-wrapper
    .selected-card-container
    .selected-card-content
    .selected-legal-text,
.selected-card-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-card-text,
.selected-card-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-legal-text,
.selected-card-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-card-text,
.selected-card-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-legal-text,
.selected-card-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-card-text,
.selected-card-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-legal-text,
.selected-legal-wrapper
    .selected-card-container
    .selected-card-content
    .selected-card-text,
.selected-legal-wrapper
    .selected-card-container
    .selected-card-content
    .selected-legal-text,
.selected-legal-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-card-text,
.selected-legal-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-legal-text,
.selected-legal-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-card-text,
.selected-legal-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-legal-text,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-card-text,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-legal-text {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    color: #ffffff;
}
.selected-card-wrapper
    .selected-card-container
    .selected-card-content
    .selected-card-text.first,
.selected-card-wrapper
    .selected-card-container
    .selected-card-content
    .selected-legal-text.first,
.selected-card-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-card-text.first,
.selected-card-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-legal-text.first,
.selected-card-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-card-text.first,
.selected-card-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-legal-text.first,
.selected-card-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-card-text.first,
.selected-card-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-legal-text.first,
.selected-legal-wrapper
    .selected-card-container
    .selected-card-content
    .selected-card-text.first,
.selected-legal-wrapper
    .selected-card-container
    .selected-card-content
    .selected-legal-text.first,
.selected-legal-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-card-text.first,
.selected-legal-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-legal-text.first,
.selected-legal-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-card-text.first,
.selected-legal-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-legal-text.first,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-card-text.first,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-legal-text.first {
    padding: 75px 25px 0 0;
    text-align: left;
}
.selected-card-wrapper
    .selected-card-container
    .selected-card-content
    .selected-card-text.last,
.selected-card-wrapper
    .selected-card-container
    .selected-card-content
    .selected-legal-text.last,
.selected-card-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-card-text.last,
.selected-card-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-legal-text.last,
.selected-card-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-card-text.last,
.selected-card-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-legal-text.last,
.selected-card-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-card-text.last,
.selected-card-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-legal-text.last,
.selected-legal-wrapper
    .selected-card-container
    .selected-card-content
    .selected-card-text.last,
.selected-legal-wrapper
    .selected-card-container
    .selected-card-content
    .selected-legal-text.last,
.selected-legal-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-card-text.last,
.selected-legal-wrapper
    .selected-card-container
    .selected-legal-content
    .selected-legal-text.last,
.selected-legal-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-card-text.last,
.selected-legal-wrapper
    .selected-legal-container
    .selected-card-content
    .selected-legal-text.last,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-card-text.last,
.selected-legal-wrapper
    .selected-legal-container
    .selected-legal-content
    .selected-legal-text.last {
    padding: 75px 0 0 25px;
    text-align: right;
}

.cards-right-container,
.legals-right-container {
    display: flex;
    justify-content: end;
}

.my-cards-modal .my-cards-modal-content,
.my-cards-modal .my-legals-modal-content,
.my-legals-modal .my-cards-modal-content,
.my-legals-modal .my-legals-modal-content {
    justify-content: center;
    align-content: space-between;
    min-height: 180px;
}
.my-cards-modal .my-cards-modal-content.centered,
.my-cards-modal .my-legals-modal-content.centered,
.my-legals-modal .my-cards-modal-content.centered,
.my-legals-modal .my-legals-modal-content.centered {
    align-content: center;
}
.my-cards-modal .my-cards-modal-content .icon-centered,
.my-cards-modal .my-legals-modal-content .icon-centered,
.my-legals-modal .my-cards-modal-content .icon-centered,
.my-legals-modal .my-legals-modal-content .icon-centered {
    text-align: center;
    margin-bottom: 25px;
}
.my-cards-modal .MuiBox-root,
.my-legals-modal .MuiBox-root {
    border-radius: 20px;
}
.my-cards-modal .modal-modal-text,
.my-legals-modal .modal-modal-text {
    color: #2f2b31;
}
.my-cards-modal #modal-modal-title,
.my-legals-modal #modal-modal-title {
    text-align: center;
}
.my-cards-modal #modal-modal-title .modal-modal-text,
.my-legals-modal #modal-modal-title .modal-modal-text {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 22px;
}
.my-cards-modal #modal-modal-description,
.my-legals-modal #modal-modal-description {
    text-align: center;
}
.my-cards-modal #modal-modal-description .modal-modal-text,
.my-legals-modal #modal-modal-description .modal-modal-text {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 17px;
    line-height: 0;
}
.my-cards-modal .modal-modal-buttons,
.my-legals-modal .modal-modal-buttons {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.my-cards-modal .modal-modal-buttons .modal-modal-button,
.my-legals-modal .modal-modal-buttons .modal-modal-button {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    text-transform: none;
    font-size: 16px;
}
.my-cards-modal .modal-modal-buttons .modal-modal-button.modal-button-delete,
.my-legals-modal .modal-modal-buttons .modal-modal-button.modal-button-delete {
    padding: 0 30px 2px;
    background-color: #e62121;
    border-radius: 500px;
    color: #ffffff;
}
.my-cards-modal .modal-modal-buttons .modal-modal-button.modal-button-back,
.my-legals-modal .modal-modal-buttons .modal-modal-button.modal-button-back {
    color: #6b666e;
    margin-right: 15px;
}
.my-cards-modal
    .modal-modal-buttons
    .modal-modal-button.modal-button-delete:hover,
.my-legals-modal
    .modal-modal-buttons
    .modal-modal-button.modal-button-delete:hover {
    background-color: #c82828;
}

.error-custom-modal-message-container {
    justify-content: center;
}
.error-custom-modal-message-container p.error-custom-modal-message-detail {
    line-height: 2;
}

.my-card-add-button,
.my-legal-add-button {
    margin: 10px 0 !important;
}

.my-legal-save-button,
.my-legal-delete-button {
    display: flex;
    align-content: center;
    margin: 0;
}
.my-legal-save-button button,
.my-legal-delete-button button {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif !important;
    font-size: 17px !important;
}

.my-legal-save-button {
    justify-content: end;
}

.my-legal-save-button button {
    color: #fd45d9 !important;
}

.my-legal-save-button button:hover {
    border-radius: 10px;
    background-color: rgba(218, 86, 214, 0.15);
}

.my-legal-delete-button button {
    color: #ff2727 !important;
}
.my-legal-delete-button button svg {
    font-size: 15px;
    margin-right: 5px;
}

.my-legal-delete-button button:hover {
    border-radius: 10px;
    background-color: rgba(255, 217, 217, 0.52);
}

.suscription-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}
.suscription-container img {
    height: 340px;
}
.suscription-container .card-suscription {
    border: 1px solid #dcdcdc;
    border-radius: 30px;
    margin: 35px 0;
    padding: 30px 40px;
    background-color: #f9f9fc;
}
.suscription-container .card-suscription p {
    font-size: 17px;
}
.suscription-container .card-suscription p span {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    margin-left: 5px;
}
.suscription-container .card-suscription .color-text-suscription {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 35px;
    line-height: 45px;
    top: 252px;
    left: 360px;
    width: auto;
    height: auto;
    letter-spacing: 0px;
    color: #4c2793;
    opacity: 1;
    margin: 0 !important;
}
.suscription-container .card-suscription .text-container-suscription {
    display: flex;
    flex-direction: column;
    padding: 0;
}
.suscription-container
    .card-suscription
    .text-container-suscription
    .title-container-suscription
    p {
    line-height: 45px;
}
.suscription-container
    .card-suscription
    .text-container-suscription
    .suscription-btn {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0 0 !important;
}

.card-suscription-active {
    border: 1px solid #dcdcdc;
    border-radius: 30px;
    margin: 35px 0;
    padding: 30px 40px;
    background-color: #f9f9fc;
}
.card-suscription-active .color-text-Dalefon-tittle {
    font-size: 85px !important;
}
.card-suscription-active .color-text-suscription {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 45px;
    top: 252px;
    left: 360px;
    width: auto;
    height: auto;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0 !important;
}
.card-suscription-active .left-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.card-suscription-active .left-column p {
    line-height: 60px !important;
    color: #454545;
}
.card-suscription-active .left-column .duration-product {
    color: #29292a;
    font-size: 20px;
    margin: 0 0 10px;
}
.card-suscription-active .left-column .duration-product .renew-product {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 23px;
    margin-left: 5px;
}
.card-suscription-active .right-column {
    display: flex;
    justify-content: center;
}

.color-text-programming-package {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 20px !important;
    text-align: center;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0 0 20px;
}

.color-text-choose_recharge {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 18px;
    top: 322px;
    left: 653px;
    width: 275px;
    height: 55px;
    text-align: left;
    letter-spacing: 0px;
    color: #2f2b31;
    opacity: 1;
}

.color-text-choose-renew {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 18px;
    top: 322px;
    left: 653px;
    width: 275px;
    height: 55px;
    text-align: left;
    letter-spacing: 0px;
    color: #2f2b31;
    opacity: 1;
}

.background-choose-renew {
    /* UI Properties */
    background: #ff00cc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #333399,
        #ff00cc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        90deg,
        rgba(72, 119, 238, 0.09),
        rgba(157, 44, 222, 0.09)
    );
    border-radius: 15px;
    padding: 30px 10px 25px;
}

.color-text-Dalefon-tittle {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 65px !important;
    top: 347px;
    left: 358px;
    width: auto;
    height: auto;
    text-align: left !important;
    letter-spacing: 0px;
    color: #4c2793;
    opacity: 1;
    text-align: center;
    margin: 18px 0 !important;
}

.button-cancel-suscription {
    font-size: 17px !important;
    text-transform: none !important;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif !important;
    color: #fd45d9 !important;
    margin-top: 30px !important;
}

.button-cancel-suscription:hover {
    background: #fcedff;
}

.title-product h1 {
    padding-top: 30px;
    color: #51328b;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif !important;
    font-size: 32px !important;
    justify-content: center;
    margin: 0 auto;
}

.cancel-suscription-container {
    display: flex;
    justify-content: center;
}

.product-coverage-container {
    text-align: center;
    margin: 20px 0 0 !important;
    color: #585858;
}

.product-services-wrapper {
    justify-content: center;
}
.product-services-wrapper .product-services-container img {
    display: block;
    max-width: 30px;
    margin: 0 auto;
}
.product-services-wrapper .product-services-container p {
    text-align: center;
    margin: 10px 0 0 !important;
}
.product-services-wrapper .product-services-container span {
    display: block;
    text-align: center;
}

/*******/
/* Flujo de Compra => Nuevas Imágenes */
.esim-compatible-container img,
.sim-compatible-container img {
    width: 75%;
    min-width: 300px;
    max-width: 400px;
}

.congratulations-container img {
    width: 25%;
    min-width: 295px;
    max-width: 300px;
}

/*******/
/* Flujo de Compra => Loaders */
.purchase-form span.MuiCircularProgress-root {
    color: #ffffff !important;
}
.purchase-form span.Circular-progress-payment {
    position: absolute;
    top: 50%;
    right: 25%;
    left: auto;
    color: #ff28dd !important;
}
.purchase-form span.Circular-progress-product {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}
.purchase-form span.Circular-progress {
    position: absolute;
    top: 50%;
    right: 25%;
    left: auto;
    color: #ff28dd !important;
}
.purchase-form span.Circular-progress-client {
    position: absolute;
    top: 50%;
    right: 25%;
    left: auto;
    color: rgb(255, 255, 255) !important;
}

/*******/
/* Sesión de usuario / Dashboard => Servicios */
.services-page .services-container {
    padding: 30px 0 0;
    margin: 0 auto;
    margin-top: 10px;
    background-color: white;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 50px 50px 0 0;
}
.services-page .services-container .services-content {
    width: 100%;
}
.services-page .services-container .services-tabs .MuiTabs-flexContainer {
    display: flex;
    justify-content: center;
}
.services-page
    .services-container
    .services-tabs
    .MuiTabs-flexContainer
    .services-tab {
    text-transform: none;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #685e80;
}
.services-page
    .services-container
    .services-tabs
    .MuiTabs-flexContainer
    .services-tab.first {
    border-right: none;
    border-radius: 11px;
}
.services-page
    .services-container
    .services-tabs
    .MuiTabs-flexContainer
    .services-tab.second {
    border-right: none;
    border-radius: 11px;
}
.services-page
    .services-container
    .services-tabs
    .MuiTabs-flexContainer
    .services-tab.third {
    border-left: none;
    border-radius: 11px;
}
.services-page .services-container .services-tabs .MuiTabs-indicator {
    background-color: transparent;
}
.services-page .services-container .services-tabs .MuiTab-root.Mui-selected {
    color: #fff;
    background: linear-gradient(90deg, #b427ff 20%, #8677f4 100%) no-repeat
        padding-box;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.service-item-footer {
    width: 100%;
    margin: 0 0 10px;
    font-weight: 700;
    color: #52456a;
}

.data-alignment {
    margin: 0;
    font-size: 17px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.data-alignment.colored {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    color: #2d263a;
    font-size: 18px;
}

.data-alignment,
.free-alignment p,
.free-alignment h4 {
    text-align: center;
}

.free-alignment h4 {
    margin: 0;
    font-size: 24px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    color: #2d263a;
}
.free-alignment p {
    font-size: 16px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    margin: 0;
}

.detail {
    margin-top: 15px !important;
}

.compatibility {
    display: flex;
    align-content: center;
    justify-content: center;
}
.compatibility .phones {
    display: flex;
    align-items: center;
    align-content: center;
}
.compatibility .phones span {
    margin: 0 10px;
    color: rgb(38, 38, 38);
}
.compatibility .phones span svg {
    font-size: 20px;
}
.compatibility button {
    background-color: transparent;
    color: #663ce6;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    text-transform: none;
}

.whatsapp-image {
    height: 80px;
    margin: 0 0 20px !important;
}

/** PROMOTIONS */
.promotions-header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
}
.promotions-header .promo-text {
    display: flex;
    justify-content: center;
}
.promotions-header .promo-text h1 {
    font-size: 35px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    margin-bottom: 55px;
}
.promotions-header .promo-text h1 span {
    color: #a361ff;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 899px) {
    .promotions-header .promo-text {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .promotions-header .promo-text h1 {
        margin-top: 10px;
        font-size: 30px;
        font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    }
    .promotions-header .promo-text h1 span {
        color: #a361ff;
        font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    }
}

.promotions-content {
    padding: 40px 0 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
}
.promotions-content h1 {
    color: #51328b;
    font-size: 28px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 32px;
    margin-top: 0;
}
.promotions-content .card-container {
    max-width: 350px;
    margin: 20px 10px;
}
.promotions-content .card-container .card {
    position: relative;
    width: 100%;
    height: 225px;
    border-radius: 20px;
    box-shadow: 0px 2px 18px 0px rgba(99, 99, 99, 0.5);
    background-color: #8f6e6e;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: box-shadow 0.54s ease-in-out;
}
.promotions-content .card-container .card .card__thumbnail {
    position: relative;
    overflow: hidden;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.promotions-content .card-container .card .card__thumbnail img {
    width: 100%;
}
.promotions-content .card-container .card:hover {
    cursor: pointer;
    box-shadow:
        0 24px 38px 3px rgba(33, 14, 67, 0.4),
        0 9px 46px 8px rgba(135, 111, 178, 0.33),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 899px) {
    .promotions-content h1 {
        font-size: 25px;
    }
}
/*** MODAL ***/
.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow: hidden;
}
.modalBackground .modalContainer {
    width: 400px;
    height: auto;
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 25px;
}
.modalBackground .modalContainer .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}
.modalBackground .modalContainer .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
}
.modalBackground .modalContainer .modal-title {
    display: inline-block;
    text-align: center;
    color: #050505;
}
.modalBackground .modalContainer .modal-title h1 {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif !important;
    margin: 0;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 25px;
}
.modalBackground .modalContainer .modal-body {
    font-size: 16px;
    text-align: center;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    color: #000000;
}
.modalBackground .modalContainer .modal-body p {
    margin-bottom: 20px;
}
.modalBackground .modalContainer .modal-body small {
    color: #666666;
    font-size: 14px;
}
.modalBackground .modalContainer .modal-body span {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    color: #51328b;
}
.modalBackground .modalContainer .modal-footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalBackground .modalContainer .modal-footer button {
    cursor: pointer;
    padding: 10px 25px;
    margin: 30px 0 10px;
    text-align: center;
    border-radius: 50px;
    font-size: 16px;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    color: #ffffff;
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(173, 39, 107) 0%,
        rgb(255, 40, 223) 100%
    );
    text-transform: none;
    border: none;
}
.modalBackground .modalContainer .modal-footer button:hover {
    transition: 1s;
    transform: scale(1.12);
}

.no-prod {
    width: 100%;
    padding: 15px 0;
    background: red;
    text-align: center;
    font-weight: 600;
    color: white;
}

/* SCSS - LANDING - QR ACTIVATION */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Tipografía ***/
.qr-not-found-container .qr-not-found-legend {
    text-align: center;
}

.qr-not-found-container,
.qr-activation-container {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
}
.qr-not-found-container .qr-header,
.qr-activation-container .qr-header {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.qr-not-found-container .qr-header .qr-header-image,
.qr-activation-container .qr-header .qr-header-image {
    width: 100%;
    height: 90%;
}
.qr-not-found-container .qr-header .logo,
.qr-activation-container .qr-header .logo {
    height: 60px;
    margin-bottom: 90px;
}
.qr-not-found-container .qr-header p,
.qr-activation-container .qr-header p {
    margin-top: 20px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 65px;
    line-height: 20px;
    margin: 0;
}
.qr-not-found-container .qr-header h1,
.qr-activation-container .qr-header h1 {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    color: white;
    font-size: 75px;
    margin: 20px 0;
    font-style: italic;
}
.qr-not-found-container .qr-introduction,
.qr-activation-container .qr-introduction {
    border-radius: 20px 20px 0 0;
    background: rgb(29, 3, 68);
    background: linear-gradient(
        360deg,
        rgb(29, 3, 68) 0%,
        rgb(67, 43, 116) 100%
    );
    padding: 65px 50px 65px 15px;
}
.qr-not-found-container .qr-introduction h1,
.qr-activation-container .qr-introduction h1 {
    font-size: 40px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.qr-not-found-container .qr-introduction p,
.qr-activation-container .qr-introduction p {
    font-size: 20px;
}
.qr-not-found-container .qr-introduction .label,
.qr-activation-container .qr-introduction .label {
    font-size: 24px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}
.qr-not-found-container .bg-container,
.qr-activation-container .bg-container {
    background-image: url("assets/images/landings/esim-activation/qr-bg-lines-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
}
.qr-not-found-container .bg-container .qr-code-container,
.qr-activation-container .bg-container .qr-code-container {
    margin: 85px 0 210px;
    display: flex;
    justify-content: center;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code,
.qr-activation-container .bg-container .qr-code-container .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code h1,
.qr-activation-container .bg-container .qr-code-container .qr-code h1 {
    font-size: 50px;
    font-family: "Poppins-ExtraBold";
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code p,
.qr-activation-container .bg-container .qr-code-container .qr-code p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .qr-box,
.qr-activation-container .bg-container .qr-code-container .qr-code .qr-box {
    margin-top: 30px;
    padding: 30px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    font-size: 20px;
    border-radius: 20px;
    max-width: 800px;
    width: 100%;
    text-align: center;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .qr-box h3,
.qr-activation-container .bg-container .qr-code-container .qr-code .qr-box h3 {
    margin: 0;
    font-family: "Poppins-SemiBold";
}
.qr-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .qr-box
    .qr-image,
.qr-activation-container
    .bg-container
    .qr-code-container
    .qr-code
    .qr-box
    .qr-image {
    display: flex;
    justify-content: flex-end;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code h5,
.qr-activation-container .bg-container .qr-code-container .qr-code h5 {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code h5 span,
.qr-activation-container .bg-container .qr-code-container .qr-code h5 span {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-style: italic;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .inactive h1,
.qr-activation-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h1 {
    font-size: 35px;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .inactive p,
.qr-activation-container .bg-container .qr-code-container .qr-code .inactive p {
    font-size: 17px;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .inactive h3,
.qr-activation-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h3 {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 40px;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .inactive h4,
.qr-activation-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h4 {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.qr-not-found-container .bg-container .qr-code-container .qr-code .inactive h2,
.qr-activation-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h2 {
    font-size: 40px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
}
.qr-not-found-container .bg-container .qr-second-video,
.qr-activation-container .bg-container .qr-second-video {
    display: flex;
    align-items: center;
}
.qr-not-found-container .bg-container .qr-second-video h1,
.qr-activation-container .bg-container .qr-second-video h1 {
    font-size: 40px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 60px;
}
.qr-not-found-container .bg-container .qr-second-video p,
.qr-activation-container .bg-container .qr-second-video p {
    font-size: 18px;
    line-height: 30px;
}
.qr-not-found-container .bg-container .qr-second-video .label,
.qr-activation-container .bg-container .qr-second-video .label {
    font-size: 24px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}
.qr-not-found-container .qr-introduction .video-responsive,
.qr-not-found-container .qr-second-video .video-responsive,
.qr-activation-container .qr-introduction .video-responsive,
.qr-activation-container .qr-second-video .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
}
.qr-not-found-container .qr-introduction .video-responsive iframe,
.qr-not-found-container .qr-introduction .video-responsive object,
.qr-not-found-container .qr-introduction .video-responsive embed,
.qr-not-found-container .qr-second-video .video-responsive iframe,
.qr-not-found-container .qr-second-video .video-responsive object,
.qr-not-found-container .qr-second-video .video-responsive embed,
.qr-activation-container .qr-introduction .video-responsive iframe,
.qr-activation-container .qr-introduction .video-responsive object,
.qr-activation-container .qr-introduction .video-responsive embed,
.qr-activation-container .qr-second-video .video-responsive iframe,
.qr-activation-container .qr-second-video .video-responsive object,
.qr-activation-container .qr-second-video .video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.qr-not-found-container .qr-footer,
.qr-activation-container .qr-footer {
    background: #15002a;
    padding: 10px 0;
    text-align: center;
    margin-top: 150px;
}

@media screen and (max-width: 899px) {
    .qr-activation-container {
        padding: 20px;
    }
    .qr-activation-container .qr-header {
        margin-bottom: 15px;
    }
    .qr-activation-container .qr-header p {
        line-height: 74px;
        text-align: center;
    }
    .qr-activation-container .qr-header h1 {
        margin: 0;
        text-align: center;
    }
    .qr-activation-container .qr-header .qr-header-image {
        display: none !important;
    }
    .qr-activation-container .qr-introduction {
        padding: 20px 0;
        background: none;
    }
    .qr-activation-container .bg-container {
        background-image: none;
    }
    .qr-activation-container .bg-container .qr-code-container {
        margin: 50px 0 0;
    }
    .qr-activation-container .bg-container .qr-code-container .qr-code h1 {
        margin-top: 0;
        font-size: 40px !important;
    }
    .qr-activation-container .bg-container .qr-code-container .qr-code .qr-box {
        padding: 20px;
    }
    .qr-activation-container
        .bg-container
        .qr-code-container
        .qr-code
        .qr-box
        h3 {
        margin: 20px 0 25px !important;
        font-family: "Poppins-Bold";
    }
    .qr-activation-container
        .bg-container
        .qr-code-container
        .qr-code
        .qr-box
        .qr-image {
        display: flex;
        justify-content: center !important;
    }
    .qr-activation-container
        .bg-container
        .qr-code-container
        .qr-code
        .inactive
        h2 {
        font-size: 40px;
    }
    .qr-activation-container .qr-footer {
        margin-top: 50px;
    }
    .qr-activation-container .video-responsive {
        margin: 30px auto !important;
        display: flex;
        justify-content: center;
    }
}
.qr-activation-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.active-qr-image {
    width: 100%;
    height: auto;
}

/* SCSS - LANDING - INVOICE SIM PURCHASE */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Variables ***/
/*** Mixins ***/
/*** Tipografía ***/
.invoice-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.invoice-not-found-container .invoice-not-found-legend {
    text-align: center;
}

.invoice-not-found-container,
.invoice-wrapper {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
}
.invoice-not-found-container .qr-header,
.invoice-wrapper .qr-header {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.invoice-not-found-container .qr-header .qr-header-image,
.invoice-wrapper .qr-header .qr-header-image {
    width: 100%;
    height: 90%;
}
.invoice-not-found-container .qr-header .logo,
.invoice-wrapper .qr-header .logo {
    height: 60px;
    margin-bottom: 90px;
}
.invoice-not-found-container .qr-header p,
.invoice-wrapper .qr-header p {
    margin-top: 20px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 65px;
    line-height: 20px;
    margin: 0;
}
.invoice-not-found-container .qr-header h1,
.invoice-wrapper .qr-header h1 {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    color: white;
    font-size: 75px;
    margin: 20px 0;
    font-style: italic;
}
.invoice-not-found-container .qr-introduction,
.invoice-wrapper .qr-introduction {
    border-radius: 20px 20px 0 0;
    background: rgb(29, 3, 68);
    background: linear-gradient(
        360deg,
        rgb(29, 3, 68) 0%,
        rgb(67, 43, 116) 100%
    );
    padding: 65px 50px 65px 15px;
}
.invoice-not-found-container .qr-introduction h1,
.invoice-wrapper .qr-introduction h1 {
    font-size: 40px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.invoice-not-found-container .qr-introduction p,
.invoice-wrapper .qr-introduction p {
    font-size: 20px;
}
.invoice-not-found-container .qr-introduction .label,
.invoice-wrapper .qr-introduction .label {
    font-size: 24px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}
.invoice-not-found-container .bg-container,
.invoice-wrapper .bg-container {
    background-image: url("assets/images/landings/esim-activation/qr-bg-lines-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
}
.invoice-not-found-container .bg-container .qr-code-container,
.invoice-wrapper .bg-container .qr-code-container {
    margin: 85px 0 210px;
    display: flex;
    justify-content: center;
}
.invoice-not-found-container .bg-container .qr-code-container .qr-code,
.invoice-wrapper .bg-container .qr-code-container .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.invoice-not-found-container .bg-container .qr-code-container .qr-code h1,
.invoice-wrapper .bg-container .qr-code-container .qr-code h1 {
    font-size: 50px;
    font-family: "Poppins-ExtraBold";
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
}
.invoice-not-found-container .bg-container .qr-code-container .qr-code p,
.invoice-wrapper .bg-container .qr-code-container .qr-code p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}
.invoice-not-found-container .bg-container .qr-code-container .qr-code .qr-box,
.invoice-wrapper .bg-container .qr-code-container .qr-code .qr-box {
    margin-top: 30px;
    padding: 30px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    font-size: 20px;
    border-radius: 20px;
    max-width: 800px;
    width: 100%;
    text-align: center;
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .qr-box
    h3,
.invoice-wrapper .bg-container .qr-code-container .qr-code .qr-box h3 {
    margin: 0;
    font-family: "Poppins-SemiBold";
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .qr-box
    .qr-image,
.invoice-wrapper .bg-container .qr-code-container .qr-code .qr-box .qr-image {
    display: flex;
    justify-content: flex-end;
}
.invoice-not-found-container .bg-container .qr-code-container .qr-code h5,
.invoice-wrapper .bg-container .qr-code-container .qr-code h5 {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.invoice-not-found-container .bg-container .qr-code-container .qr-code h5 span,
.invoice-wrapper .bg-container .qr-code-container .qr-code h5 span {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-style: italic;
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h1,
.invoice-wrapper .bg-container .qr-code-container .qr-code .inactive h1 {
    font-size: 35px;
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    p,
.invoice-wrapper .bg-container .qr-code-container .qr-code .inactive p {
    font-size: 17px;
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h3,
.invoice-wrapper .bg-container .qr-code-container .qr-code .inactive h3 {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 40px;
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h4,
.invoice-wrapper .bg-container .qr-code-container .qr-code .inactive h4 {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.invoice-not-found-container
    .bg-container
    .qr-code-container
    .qr-code
    .inactive
    h2,
.invoice-wrapper .bg-container .qr-code-container .qr-code .inactive h2 {
    font-size: 40px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
}
.invoice-not-found-container .bg-container .qr-second-video,
.invoice-wrapper .bg-container .qr-second-video {
    display: flex;
    align-items: center;
}
.invoice-not-found-container .bg-container .qr-second-video h1,
.invoice-wrapper .bg-container .qr-second-video h1 {
    font-size: 40px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 60px;
}
.invoice-not-found-container .bg-container .qr-second-video p,
.invoice-wrapper .bg-container .qr-second-video p {
    font-size: 18px;
    line-height: 30px;
}
.invoice-not-found-container .bg-container .qr-second-video .label,
.invoice-wrapper .bg-container .qr-second-video .label {
    font-size: 24px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}
.invoice-not-found-container .qr-introduction .video-responsive,
.invoice-not-found-container .qr-second-video .video-responsive,
.invoice-wrapper .qr-introduction .video-responsive,
.invoice-wrapper .qr-second-video .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
}
.invoice-not-found-container .qr-introduction .video-responsive iframe,
.invoice-not-found-container .qr-introduction .video-responsive object,
.invoice-not-found-container .qr-introduction .video-responsive embed,
.invoice-not-found-container .qr-second-video .video-responsive iframe,
.invoice-not-found-container .qr-second-video .video-responsive object,
.invoice-not-found-container .qr-second-video .video-responsive embed,
.invoice-wrapper .qr-introduction .video-responsive iframe,
.invoice-wrapper .qr-introduction .video-responsive object,
.invoice-wrapper .qr-introduction .video-responsive embed,
.invoice-wrapper .qr-second-video .video-responsive iframe,
.invoice-wrapper .qr-second-video .video-responsive object,
.invoice-wrapper .qr-second-video .video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.invoice-not-found-container .qr-footer,
.invoice-wrapper .qr-footer {
    background: #15002a;
    padding: 10px 0;
    text-align: center;
    margin-top: 150px;
}

@media screen and (max-width: 899px) {
    .invoice-wrapper .qr-header {
        margin-bottom: 15px;
    }
    .invoice-wrapper .qr-header .qr-header-image {
        display: none !important;
    }
    .invoice-wrapper .qr-introduction {
        padding: 20px 0;
        background: none;
    }
    .invoice-wrapper .bg-container {
        background-image: none;
    }
    .invoice-wrapper .bg-container .qr-code-container {
        margin: 50px 0 0;
    }
    .invoice-wrapper .bg-container .qr-code-container .qr-code h1 {
        margin-top: 0;
        font-size: 40px !important;
    }
    .invoice-wrapper .bg-container .qr-code-container .qr-code .qr-box {
        padding: 20px;
    }
    .invoice-wrapper .bg-container .qr-code-container .qr-code .qr-box h3 {
        margin: 20px 0 25px !important;
        font-family: "Poppins-Bold";
    }
    .invoice-wrapper
        .bg-container
        .qr-code-container
        .qr-code
        .qr-box
        .qr-image {
        display: flex;
        justify-content: center !important;
    }
    .invoice-wrapper .bg-container .qr-code-container .qr-code .inactive h2 {
        font-size: 40px;
    }
    .invoice-wrapper .qr-footer {
        margin-top: 50px;
    }
    .invoice-wrapper .video-responsive {
        margin: 30px auto !important;
        display: flex;
        justify-content: center;
    }
}
.invoice-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.active-qr-image {
    width: 100%;
    height: auto;
}

.invoice-content .invoice-logo {
    height: 50px;
    margin-bottom: 30px;
}
.invoice-content .invoice-img {
    max-width: 500px;
    width: 100%;
    height: auto;
}
.invoice-content .invoice-form {
    width: 100%;
    color: #ffffff;
    background: rgb(187, 136, 255);
    background: linear-gradient(
        180deg,
        rgba(187, 136, 255, 0.1797969188) 0%,
        rgba(62, 32, 85, 0) 100%
    );
    border-radius: 20px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
.invoice-content .invoice-form h1 {
    color: #ffffff;
    margin-top: 0;
}
.invoice-content .invoice-form h4 {
    margin: 0;
    font-size: 18px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
    font-weight: 500;
}
.invoice-content .invoice-form svg {
    color: white;
}
.invoice-content .invoice-form svg.help {
    font-size: 15px;
}
.invoice-content .invoice-form svg:hover {
    color: #e078d1;
}
.invoice-content .invoice-form .InputLabel {
    color: #ffffff;
    margin-top: 0;
}
.invoice-content .invoice-form input {
    color: #ffffff;
    box-sizing: border-box;
}
.invoice-content .invoice-form .invoice-btn {
    font-family: "Poppins-Bold";
    background: rgb(0, 0, 0);
    background: linear-gradient(
        174deg,
        rgb(102, 63, 130) 0%,
        rgb(77, 18, 156) 100%
    );
    width: 100%;
    padding: 10px;
    border-radius: 50px;
    margin-bottom: 30px;
}
.invoice-content .invoice-form .invoice-btn:hover {
    background: rgb(0, 0, 0);
    background: linear-gradient(
        174deg,
        rgb(89, 54, 114) 0%,
        rgb(77, 18, 156) 100%
    );
}
.invoice-content .invoice-response {
    padding: 30px auto;
}
.invoice-content .invoice-response .download {
    border-radius: 15px;
    border: 1px solid #dedede;
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
}
.invoice-content .invoice-response .download h3 {
    font-size: 16px;
    font-weight: 900 !important;
}
.invoice-content .invoice-response.error {
    text-align: center;
}
.invoice-content .invoice-response.error a {
    padding-top: 20px;
    font-weight: 900;
    text-decoration: none;
}
.invoice-content .invoice-response.error svg {
    font-size: 35px;
    color: red;
}
.invoice-content .invoice-response.success {
    text-align: center;
}
.invoice-content .invoice-response.success h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
}
.invoice-content .invoice-response.success h3 span {
    font-weight: 900;
}
.invoice-content .invoice-response.success svg {
    font-size: 45px;
    color: rgb(62, 40, 137);
    margin-bottom: 20px;
}
.invoice-content .invoice-response.success .success-svg {
    height: 50px !important;
}
.invoice-content .footer .contact .data img {
    width: 18px !important;
}

.invoice-data {
    padding: 15px 30px;
    background-color: rgba(114, 75, 155, 0.1);
    border-radius: 20px;
    color: white;
    margin-bottom: 30px !important;
}
.invoice-data h1 {
    font-size: 22px;
    margin: 0;
}
.invoice-data h2 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
}
.invoice-data .invoice-data-right {
    text-align: right;
}
.invoice-data .invoice-data-left {
    text-align: left;
}

.invoice-download-invoice {
    text-align: center;
    background-color: #210946;
    color: white;
    width: 100%;
}
.invoice-download-invoice h1 {
    font-size: 37px;
    margin-top: 5px;
}
.invoice-download-invoice p {
    font-size: 21px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}
.invoice-download-invoice .success-image {
    width: auto;
    height: 90px;
}
.invoice-download-invoice .download-form {
    max-width: 500px;
    width: 100%;
    color: #ffffff;
    background: rgb(187, 136, 255);
    background: linear-gradient(
        180deg,
        rgba(187, 136, 255, 0.1797969188) 0%,
        rgba(62, 32, 85, 0) 100%
    );
    border-radius: 20px;
    padding: 35px;
    display: flex;
    margin-top: 30px;
}
.invoice-download-invoice .download-form h1 {
    color: #ffffff;
    margin-top: 0;
}
.invoice-download-invoice .download-form h3 {
    font-weight: 500;
    font-size: 16px;
}
.invoice-download-invoice .download-form .pdf-image,
.invoice-download-invoice .download-form .xml-image {
    width: auto;
    height: 70px;
    margin-top: 15px;
}

.footer .contact .data img {
    width: 18px !important;
}

@media screen and (max-width: 899px) {
    .invoice-content .invoice-logo {
        height: 45px;
        margin-bottom: 30px;
    }
    .invoice-content .invoice-img {
        display: none !important;
    }
    .invoice-content h1 {
        margin-top: 0 !important;
    }
    .invoice-content .invoice-form {
        padding: 10px 20px;
    }
    .invoice-content .invoice-form.step-1 input {
        height: auto !important;
    }
    .invoice-content .invoice-form h4 {
        margin: 15px 0 0;
        font-size: 19px;
        font-weight: normal;
    }
    .invoice-download-invoice h1 {
        font-size: 30px;
        font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
        margin-top: 5px;
    }
    .invoice-download-invoice p {
        font-size: 18px;
        line-height: 26px;
        margin: 0;
    }
    .invoice-download-invoice .success-image {
        width: auto;
        height: 80px;
    }
    .invoice-download-invoice .download-form {
        margin: 30px 15px;
        flex-direction: column;
    }
    .invoice-download-invoice .download-form h1 {
        color: #ffffff;
        margin-top: 0;
    }
    .invoice-download-invoice .download-form .pdf-image,
    .invoice-download-invoice .download-form .xml-image {
        width: auto;
        height: 80px;
    }
    .invoice-download-invoice .download-form .pdf-image {
        margin: 15px auto 40px;
    }
    .invoice-data {
        padding: 20px !important;
        width: 100%;
    }
    .invoice-data .invoice-data-right,
    .invoice-data .invoice-data-left {
        text-align: left !important;
    }
}
.invoice-form span {
    color: #ffffff;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 17px;
}

.test {
    color: #ffffff !important;
}

.invoice-texfield {
    background-color: #ffffff;
    border-radius: 5px;
}

/* Estilos para los Radio */
.radio-input span svg:first-child path {
    color: #ffffff;
}
.radio-input svg:last-child path {
    color: #ff28df;
    border-radius: #ff28df;
}

.invoice-content .invoice-form.step-1 input {
    height: auto !important;
}

.invoice-content .invoice-flow input {
    color: #030000;
}

/* SCSS - LANDING - GIFT */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Variables ***/
/*** Mixins ***/
/*** Tipografía ***/
.gift-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gift-not-found-container .gift-not-found-legend {
    text-align: center;
}

.gift-not-found-container,
.gift-wrapper {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
}
.gift-not-found-container .gift-header,
.gift-wrapper .gift-header {
    padding: 0;
    display: flex;
    align-items: center;
}
.gift-not-found-container .gift-header .gift-header-image,
.gift-wrapper .gift-header .gift-header-image {
    width: 100%;
    height: 90%;
}
.gift-not-found-container .gift-header .logo,
.gift-wrapper .gift-header .logo {
    height: 60px;
}
.gift-not-found-container .gift-header p,
.gift-wrapper .gift-header p {
    margin-top: 20px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    font-size: 65px;
    line-height: 20px;
    margin: 0;
}
.gift-not-found-container .gift-header h1,
.gift-wrapper .gift-header h1 {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    color: white;
    font-size: 75px;
    margin: 20px 0;
    font-style: italic;
}

@media screen and (max-width: 899px) {
    .gift-wrapper .gift-header {
        margin-bottom: 0px;
    }
    .gift-wrapper .gift-header .gift-header-image {
        display: none !important;
    }
    .gift-wrapper .gift-header .logo {
        padding-top: 30px;
    }
}
.gift-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.gift-content {
    max-width: 1200px !important;
    width: 100%;
    margin: 50px 0 !important;
    border-radius: 30px;
    background-image: url(../images/landings/gift/gift-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    position: relative;
}
.gift-content .gift-img {
    max-height: 230px;
    position: absolute;
    top: 0;
    left: 0;
}
.gift-content .gift-text {
    padding: 30px 50px;
}
.gift-content .gift-text .logo {
    height: 50px;
    margin-top: 30px;
}
.gift-content .gift-text h1 {
    font-size: 45px;
    line-height: 53px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.gift-content .gift-text p {
    font-size: 17px;
}
.gift-content .gift-text p span {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 20px;
}
.gift-content .gift-form {
    width: 100%;
    color: #505050;
    background-color: white;
    border-radius: 20px;
    padding: 0 20px 10px 0;
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
.gift-content .gift-form h1 {
    color: #404040;
    margin-top: 0;
}
.gift-content .gift-form input {
    box-sizing: border-box;
}
.gift-content .gift-form .gift-btn {
    font-family: "Poppins-Bold";
    background: rgb(0, 0, 0);
    background: linear-gradient(
        174deg,
        rgb(102, 63, 130) 0%,
        rgb(77, 18, 156) 100%
    );
    width: 100%;
    padding: 10px;
    border-radius: 50px;
    margin-bottom: 30px;
}
.gift-content .gift-form .gift-btn:hover {
    background: rgb(0, 0, 0);
    background: linear-gradient(
        174deg,
        rgb(84, 62, 84) 0%,
        rgb(42, 3, 93) 100%
    );
}
.gift-content .gift-response {
    padding: 30px auto;
}
.gift-content .gift-response .download {
    border-radius: 15px;
    border: 1px solid #dedede;
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
}
.gift-content .gift-response .download h3 {
    font-size: 16px;
    font-weight: 900 !important;
}
.gift-content .gift-response.error {
    margin-top: 25px;
    text-align: center;
    line-height: 20px;
}
.gift-content .gift-response.error h3,
.gift-content .gift-response.error h4 {
    color: rgba(228, 46, 46, 0.781);
}
.gift-content .gift-response.error a {
    padding-top: 20px;
    font-weight: 900;
    text-decoration: none;
    color: rgba(228, 46, 46, 0.781);
}
.gift-content .gift-response.error svg {
    font-size: 35px;
    color: rgba(228, 46, 46, 0.781);
}
.gift-content .gift-response.success {
    margin-top: 25px;
    text-align: center;
}
.gift-content .gift-response.success h3 {
    color: #269626;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
}
.gift-content .gift-response.success h3 span {
    font-weight: 900;
}
.gift-content .gift-response.success svg {
    font-size: 45px;
    color: rgb(62, 40, 137);
    margin-bottom: 20px;
}
.gift-content .gift-response.success .success-svg {
    height: 50px !important;
}
.gift-content .footer .contact .data img {
    width: 18px !important;
}

@media screen and (max-width: 1200px) {
    .gift-content {
        margin: 25px 0 !important;
        padding: 0 20px 40px;
        background-image: none !important;
    }
    .gift-content .gift-logo {
        height: 60px;
        margin-bottom: 30px;
        margin: 0 auto !important;
    }
    .gift-content .gift-img {
        display: none !important;
    }
    .gift-content .gift-form {
        margin: 0;
        padding: 15px;
    }
    .gift-content .gift-text {
        text-align: center !important;
        margin-bottom: 20px;
        padding: 0;
    }
}
.MuiLoadingButton-loadingIndicator {
    color: white !important;
}

.esim-gift-confirmation {
    width: auto !important;
    min-width: 0 !important;
    max-width: none !important;
    max-height: 400px;
}

/* SCSS - MODALS - UPDATE-CLIENT-DATA */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Variables ***/
/*** Mixins ***/
/*** Tipografía ***/
.modal-update-client-data {
    max-width: 700px !important;
    width: 100%;
    background: rgb(93, 7, 154);
    background: radial-gradient(
        circle,
        rgb(93, 7, 154) 0%,
        rgb(57, 17, 85) 100%,
        rgb(57, 17, 85) 100%
    );
    color: white;
    padding: 35px 50px;
    border-radius: 30px;
    margin: 0 auto !important;
}
.modal-update-client-data .left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.modal-update-client-data .left-column h1 {
    font-size: 48px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 58px;
    margin: 0;
}
.modal-update-client-data .left-column img {
    width: 305px;
    margin-top: 50px;
}
.modal-update-client-data .modal-title {
    display: none;
}
.modal-update-client-data .modal-image {
    display: none;
}
.modal-update-client-data .modal-subtitle {
    margin-top: 0;
    margin-bottom: 10px;
}
.modal-update-client-data .modal-textfield {
    background-color: rgb(247, 245, 245);
    color: #000;
    margin-top: 15px;
    border-radius: 5px;
}
.modal-update-client-data .secondary-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: none;
    text-transform: none;
    box-shadow: none;
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
    font-size: 16px;
}
.modal-update-client-data .secondary-button:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    color: #f1aae5;
    background-color: transparent !important;
    box-shadow: none;
}
.modal-update-client-data .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    color: rgba(255, 255, 255, 0.9) !important;
}

.success-dialog {
    text-align: center;
    padding: 16px;
}

@media screen and (max-width: 899px) {
    .modal-update-client-data {
        padding: 35px;
    }
    .modal-update-client-data .modal-image {
        display: block;
    }
    .modal-update-client-data .modal-image img {
        width: 200px;
        margin-top: 30px;
    }
    .modal-update-client-data .modal-title {
        display: block;
        font-size: 40px;
        margin: 0 0 20px;
    }
    .modal-update-client-data .modal-title h1 {
        font-size: 56px;
        font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
        line-height: 58px;
        margin: 0;
    }
    .modal-update-client-data .left-column h1 {
        display: none;
    }
    .modal-update-client-data .left-column img {
        display: none;
    }
}
/* SCSS - LOGIN - LOGIN-SIGNUP */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Variables ***/
/*** Mixins ***/
/*** Tipografía ***/
.error-text,
.required-incidence,
.invalid-feedback {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif !important;
    font-weight: 500 !important;
    color: red !important;
    font-size: 15px !important;
}
.error-text.invalid-feedback,
.required-incidence.invalid-feedback,
.invalid-feedback.invalid-feedback {
    margin: 10px 0;
}

/* SCSS - LANDING - GIFT ACTIVATION */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/* Fonts variable */
/*** Variables ***/
/*** Mixins ***/
/*** Tipografía ***/
.gift-not-found-container .gift-not-found-legend {
    text-align: center;
}

.gift-not-found-container,
.gift-activation-container {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
}
.gift-not-found-container .gift-header,
.gift-activation-container .gift-header {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.gift-not-found-container .gift-header .gift-header-image,
.gift-activation-container .gift-header .gift-header-image {
    height: 500px;
    margin-top: 24px;
}
.gift-not-found-container .gift-header .logo,
.gift-activation-container .gift-header .logo {
    height: 60px;
    margin-bottom: 90px;
}
.gift-not-found-container .gift-header p,
.gift-activation-container .gift-header p {
    margin-top: 20px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 65px;
    line-height: 20px;
    margin: 0;
}
.gift-not-found-container .gift-header h1,
.gift-activation-container .gift-header h1 {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    color: white;
    font-size: 75px;
    margin: 20px 0;
    font-style: italic;
}
.gift-not-found-container .gift-introduction-wrapper,
.gift-activation-container .gift-introduction-wrapper {
    margin: 0 auto;
}
.gift-not-found-container .gift-introduction,
.gift-activation-container .gift-introduction {
    border-radius: 20px 20px 0 0;
    background: rgb(29, 3, 68);
    background: linear-gradient(
        360deg,
        rgb(29, 3, 68) 0%,
        rgb(67, 43, 116) 100%
    );
    padding: 40px 50px 65px 15px;
}
.gift-not-found-container .gift-introduction .gift-introduction-text,
.gift-activation-container .gift-introduction .gift-introduction-text {
    padding: 0 30px;
}
.gift-not-found-container .gift-introduction h1,
.gift-activation-container .gift-introduction h1 {
    font-size: 40px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.gift-not-found-container .gift-introduction p,
.gift-activation-container .gift-introduction p {
    font-size: 20px;
}
.gift-not-found-container .gift-introduction p span,
.gift-activation-container .gift-introduction p span {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}
.gift-not-found-container .gift-introduction .label,
.gift-activation-container .gift-introduction .label {
    font-size: 24px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-style: italic;
}
.gift-not-found-container .gift-introduction .emisor-image,
.gift-activation-container .gift-introduction .emisor-image {
    height: 60px;
}
.gift-not-found-container .gift-introduction .message-box,
.gift-activation-container .gift-introduction .message-box {
    border-radius: 20px;
    padding: 5px 30px;
    color: #373636;
    background-color: white;
    font-size: 16px;
}
.gift-not-found-container .gift-introduction .message-box h3,
.gift-activation-container .gift-introduction .message-box h3 {
    font-size: 17px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.gift-not-found-container .bg-container,
.gift-activation-container .bg-container {
    background-image: url("assets/images/landings/esim-activation/qr-bg-lines-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
}
.gift-not-found-container .bg-container .gift-code-container,
.gift-activation-container .bg-container .gift-code-container {
    margin: 85px 0 210px;
    display: flex;
    justify-content: center;
}
.gift-not-found-container .bg-container .gift-code-container .gift-code,
.gift-activation-container .bg-container .gift-code-container .gift-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.gift-not-found-container .bg-container .gift-code-container .gift-code h1,
.gift-activation-container .bg-container .gift-code-container .gift-code h1 {
    font-size: 50px;
    font-family: "Poppins-ExtraBold";
    margin-bottom: 0;
    margin-top: 20px;
    text-align: center;
}
.gift-not-found-container .bg-container .gift-code-container .gift-code p,
.gift-activation-container .bg-container .gift-code-container .gift-code p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-qr-text,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-qr-text {
    padding: 30px;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-box,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-box {
    margin-top: 30px;
    padding: 30px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    font-size: 20px;
    border-radius: 20px;
    max-width: 800px;
    width: 100%;
    text-align: center;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-box
    h3,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-box
    h3 {
    margin: 0;
    font-family: "Poppins-SemiBold";
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-box
    .gift-image,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .gift-box
    .gift-image {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
    background: rgb(54, 7, 71);
    background: linear-gradient(346deg, rgb(54, 7, 71) 0%, rgb(38, 15, 71) 50%);
    border-radius: 30px;
}
.gift-not-found-container .bg-container .gift-code-container .gift-code h5,
.gift-activation-container .bg-container .gift-code-container .gift-code h5 {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.gift-not-found-container .bg-container .gift-code-container .gift-code h5 span,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    h5
    span {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-style: italic;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h1,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h1 {
    font-size: 35px;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    p,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    p {
    font-size: 17px;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h3,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h3 {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 40px;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h4,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h4 {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}
.gift-not-found-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h2,
.gift-activation-container
    .bg-container
    .gift-code-container
    .gift-code
    .inactive
    h2 {
    font-size: 40px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
}
.gift-not-found-container .bg-container .gift-second-video,
.gift-activation-container .bg-container .gift-second-video {
    display: flex;
    align-items: center;
}
.gift-not-found-container .bg-container .gift-second-video h1,
.gift-activation-container .bg-container .gift-second-video h1 {
    font-size: 40px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 60px;
}
.gift-not-found-container .bg-container .gift-second-video p,
.gift-activation-container .bg-container .gift-second-video p {
    font-size: 18px;
    line-height: 30px;
}
.gift-not-found-container .bg-container .gift-second-video .label,
.gift-activation-container .bg-container .gift-second-video .label {
    font-size: 24px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}
.gift-not-found-container .gift-introduction .video-responsive,
.gift-not-found-container .gift-second-video .video-responsive,
.gift-activation-container .gift-introduction .video-responsive,
.gift-activation-container .gift-second-video .video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
}
.gift-not-found-container .gift-introduction .video-responsive iframe,
.gift-not-found-container .gift-introduction .video-responsive object,
.gift-not-found-container .gift-introduction .video-responsive embed,
.gift-not-found-container .gift-second-video .video-responsive iframe,
.gift-not-found-container .gift-second-video .video-responsive object,
.gift-not-found-container .gift-second-video .video-responsive embed,
.gift-activation-container .gift-introduction .video-responsive iframe,
.gift-activation-container .gift-introduction .video-responsive object,
.gift-activation-container .gift-introduction .video-responsive embed,
.gift-activation-container .gift-second-video .video-responsive iframe,
.gift-activation-container .gift-second-video .video-responsive object,
.gift-activation-container .gift-second-video .video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.gift-not-found-container .gift-footer,
.gift-activation-container .gift-footer {
    background: #15002a;
    padding: 10px 0;
    text-align: center;
    margin-top: 150px;
}

@media screen and (max-width: 899px) {
    .gift-activation-container {
        padding: 20px;
    }
    .gift-activation-container .bg-container .gift-second-video h1 {
        line-height: 48px;
    }
    .gift-activation-container .gift-header {
        margin-bottom: 15px;
    }
    .gift-activation-container .gift-header p {
        line-height: 72px;
        text-align: center;
    }
    .gift-activation-container .gift-header h1 {
        margin: 0;
        text-align: center;
        line-height: 70px;
    }
    .gift-activation-container .gift-header .gift-header-image {
        display: none !important;
    }
    .gift-activation-container .gift-header .logo {
        margin-bottom: 30px;
    }
    .gift-activation-container .gift-introduction {
        padding: 20px 0;
        background: none;
    }
    .gift-activation-container .gift-introduction .gift-introduction-text {
        margin-bottom: 30px;
    }
    .gift-activation-container .bg-container {
        background-image: none;
    }
    .gift-activation-container .bg-container .gift-code-container {
        margin: 50px 0 0;
    }
    .gift-activation-container
        .bg-container
        .gift-code-container
        .gift-code
        h1 {
        margin-top: 0;
        font-size: 40px !important;
    }
    .gift-activation-container
        .bg-container
        .gift-code-container
        .gift-code
        .gift-box {
        padding: 20px;
    }
    .gift-activation-container
        .bg-container
        .gift-code-container
        .gift-code
        .gift-box
        h3 {
        margin: 20px 0 25px !important;
        font-family: "Poppins-Bold";
    }
    .gift-activation-container
        .bg-container
        .gift-code-container
        .gift-code
        .gift-box
        .gift-image {
        display: flex;
        justify-content: center !important;
    }
    .gift-activation-container
        .bg-container
        .gift-code-container
        .gift-code
        .inactive
        h2 {
        font-size: 40px;
    }
    .gift-activation-container .gift-footer {
        margin-top: 50px;
    }
    .gift-activation-container .video-responsive {
        margin: 30px auto !important;
        display: flex;
        justify-content: center;
    }
}
.gift-landing-content {
    max-width: 1200px !important;
    width: 100%;
    margin: 50px 0 !important;
    border-radius: 30px;
    background-image: url(../images/landings/gift/gift-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.gift-landing-content .gift-landing-img {
    max-height: 230px;
    position: absolute;
    top: 0;
    left: 0;
}
.gift-landing-content .gift-landing-text {
    padding: 100px 30px 20px;
}
.gift-landing-content .gift-landing-text .logo {
    height: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
}
.gift-landing-content .gift-landing-text h1 {
    font-size: 45px;
    line-height: 42px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
    line-height: 13px;
    padding: 0;
}
.gift-landing-content .gift-landing-text h1 span {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}
.gift-landing-content .gift-landing-text p {
    font-size: 20px;
    margin-top: 20px;
    display: inline;
}
.gift-landing-content .gift-landing-text p span {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 23px;
}
.gift-landing-content .gift-landing-text p .signos {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    color: #68c6dd;
    display: inline;
}
.gift-landing-content .gift-landing-text h2 {
    margin-top: 30px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.gift-landing-content .gift-landing-text h2 span {
    font-size: 18px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}
.gift-landing-content .gift-landing-text h2 svg {
    font-size: 20;
    font-weight: bold;
}
.gift-landing-content .active-gift-image {
    margin: 0 auto;
    width: auto;
    height: 200px;
    background: rgb(203, 84, 154);
    background: linear-gradient(
        90deg,
        rgb(203, 84, 154) 0%,
        rgb(139, 23, 103) 100%
    );
    border-radius: 20px;
    padding: 20px;
}
.gift-landing-content .gift-code-container {
    text-align: center;
}
.gift-landing-content .gift-code-container .gift-qr-text h1 {
    font-size: 25px;
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 20px;
    width: 50%;
    margin: 0 auto;
    letter-spacing: 3px;
}
.gift-landing-content .gift-code-container .gift-qr-text h3 {
    font-size: 20px;
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}
.gift-landing-content .gift-code-container .gift-qr-text p {
    font-size: 18px;
}
.gift-landing-content .gift-response {
    padding: 30px auto;
}
.gift-landing-content .gift-response .download {
    border-radius: 15px;
    border: 1px solid #dedede;
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
}
.gift-landing-content .gift-response .download h3 {
    font-size: 16px;
    font-weight: 900 !important;
}
.gift-landing-content .gift-response.error {
    margin-top: 25px;
    text-align: center;
    line-height: 20px;
}
.gift-landing-content .gift-response.error h3,
.gift-landing-content .gift-response.error h4 {
    color: rgba(228, 46, 46, 0.781);
}
.gift-landing-content .gift-response.error a {
    padding-top: 20px;
    font-weight: 900;
    text-decoration: none;
    color: rgba(228, 46, 46, 0.781);
}
.gift-landing-content .gift-response.error svg {
    font-size: 35px;
    color: rgba(228, 46, 46, 0.781);
}
.gift-landing-content .gift-response.success {
    margin-top: 25px;
    text-align: center;
}
.gift-landing-content .gift-response.success h3 {
    color: #269626;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
}
.gift-landing-content .gift-response.success h3 span {
    font-weight: 900;
}
.gift-landing-content .gift-response.success svg {
    font-size: 45px;
    color: rgb(62, 40, 137);
    margin-bottom: 20px;
}
.gift-landing-content .gift-response.success .success-svg {
    height: 50px !important;
}
.gift-landing-content .footer .contact .data img {
    width: 18px !important;
}

@media screen and (max-width: 1200px) {
    .gift-landing-content {
        margin: 25px 0 !important;
        padding: 0 0 40px;
        background-image: none !important;
        display: flex;
        align-items: center !important;
    }
    .gift-landing-content .gift-landing-logo {
        height: 60px;
        margin-bottom: 30px;
        margin: 0 auto !important;
    }
    .gift-landing-content .gift-landing-img {
        display: none !important;
    }
    .gift-landing-content .gift-landing-text {
        text-align: center !important;
        padding: 0 !important;
    }
    .gift-landing-content .gift-landing-text .logo {
        margin: 0;
    }
    .gift-landing-content .gift-landing-text h1 {
        line-height: 0;
    }
    .gift-landing-content .gift-landing-text p {
        margin-top: 30px !important;
    }
    .gift-landing-content .gift-landing-text .responsive-title {
        line-height: 50px !important;
    }
    .gift-landing-content .gift-code-container {
        border-radius: 30px;
        background-image: url(../images/landings/gift/gift-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px !important;
        margin: 0 10px;
    }
    .gift-landing-content .gift-code-container .gift-qr-text h1 {
        width: 100% !important;
        padding: 10px 0;
    }
}
.gift-activation-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.active-gift-image {
    width: 100%;
    height: auto;
}

/* SCSS - LANDING - LEAD RECOVERY */
/*** FONTS ***/
/* Poppins FONT*/
@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/poppins/Poppins-Bold.otf");
    src:
        local("Poppins Bold"),
        local("Poppins-Bold"),
        url("../fonts/poppins/Poppins-Bold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BoldItalic";
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
    src:
        local("Poppins BoldItalic"),
        local("Poppins-BoldItalic"),
        url("../fonts/poppins/Poppins-BoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Italic";
    src: url("../fonts/poppins/Poppins-Italic.otf");
    src:
        local("Poppins Italic"),
        local("Poppins-Italic"),
        url("../fonts/poppins/Poppins-Italic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraLight";
    src: url("../fonts/poppins/Poppins-ExtraLight.otf");
    src:
        local("Poppins Extra Light"),
        local("Poppins-ExtraLight"),
        url("../fonts/poppins/Poppins-ExtraLight.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/poppins/Poppins-Light.otf");
    src:
        local("Poppins Light"),
        local("Poppins-Light"),
        url("../fonts/poppins/Poppins-Light.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/poppins/Poppins-Medium.otf");
    src:
        local("Poppins Medium"),
        local("Poppins-Medium"),
        url("../fonts/poppins/Poppins-Medium.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-MediumItalic";
    src: url("../fonts/poppins/Poppins-MediumItalic.otf");
    src:
        local("Poppins Medium Italic"),
        local("Poppins-MediumItalic"),
        url("../fonts/poppins/Poppins-MediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/poppins/Poppins-Regular.otf");
    src:
        local("Poppins Regular"),
        local("Poppins-Regular"),
        url("../fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
    src:
        local("Poppins SemiBold"),
        local("Poppins-SemiBold"),
        url("../fonts/poppins/Poppins-SemiBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("../fonts/poppins/Poppins-ExtraBold.otf");
    src:
        local("Poppins ExtraBold"),
        local("Poppins-ExtraBold"),
        url("../fonts/poppins/Poppins-ExtraBold.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url("../fonts/poppins/Poppins-ExtraBoldItalic.otf");
    src:
        local("Poppins ExtraBoldItalic"),
        local("Poppins-ExtraBoldItalic"),
        url("../fonts/poppins/Poppins-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/poppins/Poppins-Black.otf");
    src:
        local("Poppins Black"),
        local("Poppins-Black"),
        url("../fonts/poppins/Poppins-Black.otf") format("opentype");
}
@font-face {
    font-family: "Poppins-BlackItalic";
    src: url("../fonts/poppins/Poppins-BlackItalic.otf");
    src:
        local("Poppins Black Italic"),
        local("Poppins-BlackItalic"),
        url("../fonts/poppins/Poppins-BlackItalic.otf") format("opentype");
}
/*** Variables ***/
/* Fonts variable */
/*** Poppins ***/
.poppins-bold-font {
    font-family: Poppins-Bold, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-font {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
}

.poppins-extralight-font {
    font-family: Poppins-ExtraLight, Arial, Helvetica, sans-serif;
}

.poppins-light-font {
    font-family: Poppins-Light, Arial, Helvetica, sans-serif;
}

.poppins-medium-font {
    font-family: Poppins-Medium, Arial, Helvetica, sans-serif;
}

.poppins-medium-italic-font {
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif;
}

.poppins-regular-font {
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
}

.poppins-semibold-font {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
}

.poppins-bold-italic-font {
    font-family: Poppins-BoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-extrabold-italic-font {
    font-family: Poppins-ExtraBoldItalic, Arial, Helvetica, sans-serif;
}

.poppins-black-font {
    font-family: Poppins-Black, Arial, Helvetica, sans-serif;
}

.poppins-black-italic-font {
    font-family: Poppins-BlackItalic, Arial, Helvetica, sans-serif;
}

/* Font size variables */
.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-26 {
    font-size: 26px;
}

.font-size-28 {
    font-size: 28px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-36 {
    font-size: 36px;
}

.font-size-38 {
    font-size: 38px;
}

.font-size-40 {
    font-size: 40px;
}

.font-size-45 {
    font-size: 45px;
}

.font-size-50 {
    font-size: 50px;
}

.font-size-55 {
    font-size: 55px;
}

.font-size-60 {
    font-size: 60px;
}

.font-size-65 {
    font-size: 65px;
}

.font-size-70 {
    font-size: 70px;
}

.font-size-75 {
    font-size: 75px;
}

.font-size-80 {
    font-size: 80px;
}

.font-size-85 {
    font-size: 85px;
}

.font-size-90 {
    font-size: 90px;
}

.font-size-95 {
    font-size: 95px;
}

.font-size-100 {
    font-size: 100px;
}

.font-size-120 {
    font-size: 120px;
}

.font-size-150 {
    font-size: 150px;
}

/*** Márgenes ***/
.m-0 {
    margin: 0 auto !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-tb-15 {
    margin: 15px 0 !important;
}

.m-tb-20 {
    margin: 20px 0 !important;
}

.m-tb-40 {
    margin: 40px 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-t-10 {
    padding-top: 10px;
}

.p-t-30 {
    padding-top: 30px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-tb-10 {
    padding: 10px 0 20px;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-r {
    padding-right: 10px;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-30 {
    padding-left: 30px;
}

.lh-26 {
    line-height: 26px;
}

.f-s-24 {
    font-size: 24px !important;
}

.no-bg {
    background-color: transparent !important;
    color: white !important;
}

.right,
.right-data {
    position: absolute;
    right: 0;
}

.right p,
.right-data p {
    text-align: right;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.green {
    color: #29b77b !important;
}

.red {
    color: #cc1616 !important;
}

.purple-font {
    color: #51328b !important;
}

.container-min-height-500 {
    min-height: 500px;
}

/******/
.line-height-200 {
    line-height: 200%;
}

.display-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/*** Responsivo ***/
/*** Variables ***/
/*** Mixins ***/
.lead-recovery-wrapper {
    margin: 25px 0;
    align-items: center;
}

.lead-container-congratulations {
    background: transparent linear-gradient(180deg, #302651 0%, #441959 100%) 0%
        0% no-repeat padding-box;
    border-radius: 15px;
}

.lead-recovery-section.white-bg {
    background: #ffffff;
    border-radius: 20px;
    color: #2f2b31;
}
.lead-recovery-section .lead-recovery-column .MuiGrid-root.MuiGrid-item {
    display: flex;
    justify-content: center;
}
.lead-recovery-section .first-section {
    padding: 25px 75px 25px 0px;
}
.lead-recovery-section .input-container > div > div {
    margin: 0px 0 !important;
}
.lead-recovery-section .input-container > div {
    margin: 0px 0 !important;
}
.lead-recovery-section .span-word-title {
    background-color: white;
    color: #441556;
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
}

.compatibility-image {
    max-width: 100%;
    max-height: 450px;
    margin: 0px auto;
}

.input-container {
    margin: 20px 0px !important;
}
.input-container input:hover {
    border-color: #441556 !important;
}
.input-container label {
    padding: 0px;
    font-weight: 600;
    font-size: 15px;
    color: #5a5a5a;
}

/*********/
.lead-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lead-not-found-container .lead-not-found-legend {
    text-align: center;
}

.lead-not-found-container,
.lead-wrapper {
    width: 100%;
    min-height: 100vh;
    background: transparent
        radial-gradient(
            closest-side at 25% 52%,
            #461c7c 0%,
            #1c163f 50%,
            #190a20 100%
        )
        0% 0% no-repeat padding-box;
    color: white;
}

.lead-content .lead-logo {
    height: 50px;
    margin-bottom: 30px;
}
.lead-content .lead-img {
    max-height: 500px;
}
.lead-content .lead-form {
    width: 100%;
    color: #505050;
    background: transparent
        linear-gradient(
            180deg,
            rgba(187, 136, 255, 0.5607843137) 0%,
            rgba(62, 32, 85, 0) 100%
        )
        0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 35px;
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
.lead-content .lead-form h1 {
    color: #ffffff;
    margin-top: 0;
}
.lead-content .lead-form .InputLabel {
    color: #ffffff;
    margin-top: 0;
}
.lead-content .lead-form input {
    color: #ffffff;
    box-sizing: border-box;
}
.lead-content .lead-form .lead-btn {
    font-family: "Poppins-Bold";
    background: rgb(0, 0, 0);
    background: linear-gradient(
        174deg,
        rgb(102, 63, 130) 0%,
        rgb(77, 18, 156) 100%
    );
    width: 100%;
    padding: 10px;
    border-radius: 50px;
    margin-bottom: 30px;
}
.lead-content .lead-form .lead-btn:hover {
    background: rgb(0, 0, 0);
    background: linear-gradient(
        174deg,
        rgb(84, 62, 84) 0%,
        rgb(42, 3, 93) 100%
    );
}
.lead-content .lead-response {
    padding: 30px auto;
}
.lead-content .lead-response .download {
    border-radius: 15px;
    border: 1px solid #dedede;
    padding: 20px;
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
}
.lead-content .lead-response .download h3 {
    font-size: 16px;
    font-weight: 900 !important;
}
.lead-content .lead-response.error {
    text-align: center;
}
.lead-content .lead-response.error a {
    padding-top: 20px;
    font-weight: 900;
    text-decoration: none;
}
.lead-content .lead-response.error svg {
    font-size: 35px;
    color: red;
}
.lead-content .lead-response.success {
    text-align: center;
}
.lead-content .lead-response.success h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
}
.lead-content .lead-response.success h3 span {
    font-weight: 900;
}
.lead-content .lead-response.success svg {
    font-size: 45px;
    color: rgb(62, 40, 137);
    margin-bottom: 20px;
}
.lead-content .lead-response.success .success-svg {
    height: 50px !important;
}
.lead-content .footer .contact .data img {
    width: 18px !important;
}

button.compatibility-btn {
    padding: 12px 0px;
    text-transform: none;
    background: transparent linear-gradient(180deg, #663ce6 0%, #331e73 100%) 0%
        0% no-repeat padding-box;
    border-radius: 25px;
    color: #ffffff;
    width: 100%;
}

.lead-recovery-imei-element {
    flex-basis: 100%;
    justify-content: center;
}
.lead-recovery-imei-element img {
    width: 350px;
}

.whatsapp-compatibility-image {
    width: 200px;
}

@media screen and (max-width: 899px) {
    .lead-recovery-wrapper {
        margin: 0px 0;
        align-items: center;
    }
    .lead-content .lead-logo {
        height: 35px;
        margin-bottom: 0px;
    }
    .lead-content .lead-img {
        display: none !important;
    }
    .lead-content .lead-form {
        padding: 15px;
    }
    .lead-content .lead-form.step-1 input {
        height: auto !important;
    }
    .responsive-hidden {
        display: none !important;
    }
    .lead-recovery-imei-element img {
        width: 250px;
    }
    .whatsapp-compatibility-image {
        width: 175px;
    }
}
.lead-form span {
    color: #ffffff;
}

/* Estilos para los Radio */
.radio-input span svg:first-child path {
    color: #ffffff;
}
.radio-input svg:last-child path {
    color: #ff28df;
}

.lead-content .lead-form.step-1 input {
    height: auto !important;
}

.lead-content .lead-flow input {
    color: #030000;
}

/* IMEI Responses  */
.lead-recovery-imei-section.centered {
    text-align: center;
}
.lead-recovery-imei-section .centered {
    width: 100%;
    text-align: center;
}
.lead-recovery-imei-section.response-purple {
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #ffffff;
}
.lead-recovery-imei-section.response-purple button.return-link {
    font-family: Poppins-SemiBold, Arial, Helvetica, sans-serif;
    color: #dd49e8;
}
.lead-recovery-imei-section.response-purple button.return-link:hover,
.lead-recovery-imei-section.response-purple button.return-link:focus,
.lead-recovery-imei-section.response-purple button.return-link:active,
.lead-recovery-imei-section.response-purple button.return-link:visited,
.lead-recovery-imei-section.response-purple button.return-link:link {
    background-color: transparent;
}
.lead-recovery-imei-section.response-purple img,
.lead-recovery-imei-section.response-purple button.return-link {
    flex-basis: 100%;
    flex-grow: 1;
}

.lead-recovery-imei-esim {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    background: #f6f6f6;
    border-radius: 15px;
}
.lead-recovery-imei-esim .lead-recovery-imei-esim-data {
    flex-grow: 1;
    flex-basis: 100%;
    justify-content: center;
}
.lead-recovery-imei-esim .lead-recovery-imei-esim-data img {
    width: auto;
    height: 60px;
    max-height: none;
    min-height: 0;
}
.lead-recovery-imei-esim .lead-recovery-imei-esim-data p {
    text-align: center;
}

.phone-compatibility-image {
    max-width: 20px !important;
}

.clasedeprueba {
    width: 100%;
    max-width: 1100px !important;
    margin: 0 auto !important;
    padding: 20px 0 0;
    position: relative;
}

@media screen and (max-width: 899px) {
    .products-tab div {
        justify-content: space-between;
    }
    .products-tab div button.tab.main {
        margin: 0 !important;
        width: 47.5% !important;
        height: 70px !important;
        font-size: 22px !important;
    }
    .products {
        padding: 0 30px !important;
    }
    .products h4 {
        text-align: center;
    }
    .recommended-container .recommended-label {
        left: 25%;
        right: 25%;
    }
}
.bag-container.bag-content .share-data {
    margin-bottom: 10px;
}
.bag-container.bag-content .share-data p {
    font-size: 14px;
    font-family: Poppins-MediumItalic, Arial, Helvetica, sans-serif !important;
    margin: 0;
}
.bag-container.bag-content .share-data p img {
    height: 15px;
    margin-right: 10px;
}

/*** Número actualizado ***/
.number-changed-page .number-changed-component {
    min-height: 100vh;
    padding: 100px 0 50px;
    background: transparent linear-gradient(181deg, #22035b 0%, #5d079a 100%) 0%
        0% no-repeat padding-box;
}
.number-changed-page .number-changed-component .number-changed-image {
    display: flex;
    justify-content: center;
}
.number-changed-page .number-changed-component .number-changed-image img {
    width: 300px;
}
.number-changed-page .number-changed-component .number-changed-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.number-changed-page
    .number-changed-component
    .number-changed-text
    .number-changed-title
    h1 {
    font-family: Poppins-ExtraBold, Arial, Helvetica, sans-serif;
    font-size: 30px;
}
.number-changed-page
    .number-changed-component
    .number-changed-text
    .number-changed-description {
    display: flex;
    justify-content: center;
}
.number-changed-page
    .number-changed-component
    .number-changed-text
    .number-changed-description
    p {
    max-width: 500px;
    font-family: Poppins-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
}
.number-changed-page .number-changed-component h1,
.number-changed-page .number-changed-component p {
    color: white;
    text-align: center;
}
.number-changed-page .number-changed-component .number-changed-button {
    display: flex;
    justify-content: center;
}
.number-changed-page .number-changed-component .number-changed-button button {
    width: 100%;
    margin: 0 !important;
}

.imei-popover .MuiPaper-elevation {
    max-width: 25vw !important;
}

@media screen and (max-width: 899px) {
    .imei-popover .MuiPaper-elevation {
        max-width: 85vw !important;
    }
}

