.gift-container {
    display: flex;

    .tel-link {
        color: #fc2bdc !important;
        text-decoration: none !important;
    }

    .logo-title {
        height: auto;
        width: 170px;
    }
    .gift-image {
        display: none;
    }
    .card-container {
        background: rgb(144, 205, 252);
        background: linear-gradient(150deg, #393c8e 30%, #a42e7b 100%);
        font-family: "Poppins-Regular";
        box-shadow: rgba(27, 25, 29, 0.418) 0px 20px 30px -10px;
        color: white;
        border-radius: 30px;
        position: relative;
        z-index: 2;
        padding: 35px 0 20px 0;
        max-width: 470px;
        .logo-image {
            height: auto;
            width: 120px;
        }
        .ribbon-image {
            height: auto;
            width: 177px;
            position: absolute;
            left: 0;
            top: 0;
        }
        .circular-icon {
            background-color: #fc2bdc;
            border-radius: 50%;
            padding: 3px;
        }

        .img-social-media {
            width: 300px;
            height: 170px;
            right: -25px;
            margin-top: 6px;
        }
        .img-social-media-responsive {
            width: 250px;
            height: auto;
            margin: 0 auto;
        }
    }
}

.card-gift-container {
    display: flex;
    flex-direction: column;
    background: white;
    text-align: left;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    padding: 30px;
    color: #262626;
}

.layout-conteiner {
    height: 100vh;
    padding-top: 80px;
    display: flex;
    flex-direction: column;

    .font-bold {
        font-weight: bold;
        font-family: "Poppins-bold";
    }
    .font-semibold {
        font-weight: 600;
        font-family: "Poppins-SemiBold";
    }

    .purple-color {
        color: #a361ff;
    }
    .white-color {
        color: #fff;
    }
}

@media screen and (max-width: 899px) {
    .gift-container {
        padding: 0px !important;
        margin: 0px !important;
        .gift-image {
            display: block;
        }
        font-family: "Poppins-Regular";
        .logo-title {
            display: none;
        }
        .card-container {
            background: transparent;
            padding: 0;
            box-shadow: none;
            max-width: 100%;
            max-height: 100%;
            .logo-image,
            .ribbon-image {
                display: none;
            }
            .price-container {
                display: none;
            }
            .container-responsive {
                width: 100%;
                height: 100%;
                background: transparent
                    linear-gradient(
                        182deg,
                        #200340 0%,
                        #1c163f 50%,
                        #7011a7 100%
                    )
                    0% 0% no-repeat padding-box;
                padding-bottom: 40px;
            }
        }
    }
    .card-gift-container {
        color: white;
        background: transparent;
        box-shadow: none;
        padding: 50px 0px 0px;
        margin: 0px;
        width: 100%;
    }

    .card-gift-container span.MuiRadio-root,
    .card-gift-container label {
        color: white !important;
    }
    .card-gift-container label,
    .card-gift-container label span {
        font-size: 14px;
    }

    .card-gift-container .container-custom-button {
        margin-top: -50px;
    }

    .layout-conteiner {
        height: 100%;
        padding-top: 30px;
        padding-bottom: 40px;
    }
}
