.gift-verification-container {
    .font-medium {
        font-weight: 600;
    }
    .verification-form {
        margin-top: 29px;
        input[id^="inputVC-"] {
            width: 30px !important;
            height: 30px;
        }

        div[status] {
            display: none;
        }

        .sc-qRuZU {
            display: flex;
            justify-content: space-between;
            input[id^="inputVC-"] {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .error-verification {
        .error-title {
            display: flex;
            align-items: center;
            font-size: 11px;
            color: #ec0000;
        }
        .error-forward {
            font-size: 14px;
            cursor: pointer;
        }

        @media screen and (max-width: 899px) {
            .error-title {
                color: #fff;
            }
            .error-forward {
                color: #a361ff;
            }
        }
    }

    .pointer {
        cursor: pointer;
    }
}
